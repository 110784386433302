import { Box, Button, Link as MuiLink, IconButton, styled, css, Stack } from '@mui/material';
import { Link } from '@tanstack/react-router';

import MailIcon from '@/assets/icons/mail-icon.svg?react';
import { CONTACT_EMAIL } from '@/modules/ContactEmail';

export const footerLinks = [
  { title: 'Über das Projekt', to: '/ueber-das-projekt' },
  { title: 'Datenschutz', to: '/datenschutz' },
  { title: 'Impressum', href: 'https://www.trafficon.eu/impressum/' },
];

const StyledFooter = styled(Box)(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.up('md')} {
      display: block;
      margin-top: auto;
      padding: ${theme.spacing(2)};
    }
  `,
);

const StyledNav = styled(Box)(css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`);

const StyledButton = styled(Button)(css`
  font-size: 0.65rem;
  text-transform: uppercase;
  border: none;
`) as typeof Button;

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
) as typeof IconButton;

export function Footer() {
  return (
    <StyledFooter component="footer">
      <StyledNav component="nav">
        <Stack direction="row" component="ul" sx={{ listStyle: 'none', paddingLeft: 0, margin: 0 }}>
          {footerLinks.map(({ title, to, href }) => (
            <Box component="li" key={title} sx={{ lineHeight: 0 }}>
              <StyledButton {...((to && { component: Link, to }) || (href && { component: MuiLink, href }))}>
                {title}
              </StyledButton>
            </Box>
          ))}
        </Stack>
        <StyledIconButton component={MuiLink} href={`mailto:${CONTACT_EMAIL}`} aria-label="Kontakt">
          <MailIcon />
        </StyledIconButton>
      </StyledNav>
    </StyledFooter>
  );
}
