import { ReactNode } from 'react';

import { useMapStore } from '@/stores/useMapStore';
import { MapState } from '@/types/MapState';

type LayerVisibleProps = { layer: keyof MapState['layersControl']; children: ReactNode };

export function LayerVisible({ layer, children }: LayerVisibleProps) {
  const layersControl = useMapStore((state) => state.layersControl);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return layersControl[layer] ? <>{children}</> : null;
}
