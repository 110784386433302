import { DetailWrapper } from '@/components/result/DetailWrapper';
import { LegDurationLengthMeta } from '@/components/result/LegDurationLengthMeta';
import { ModeIcon } from '@/components/routing/ModeIcon';
import { TriasHelper } from '@/modules/TriasHelper';
import { InterchangeLegStructure } from '@/modules/api-routing';
import { isMode } from '@/types/Mode';

type InterchangeLegDetailProps = { leg: InterchangeLegStructure; start?: boolean; end?: boolean };

export function InterchangeLegDetail({ leg, start, end }: InterchangeLegDetailProps) {
  return (
    <>
      {start && (
        <DetailWrapper
          bold
          left={TriasHelper.formatTimestamp(leg.timeWindowStart)}
          right={leg.legStart?.locationName?.text}
        />
      )}
      <DetailWrapper
        left={isMode(leg.interchangeMode) && <ModeIcon mode={leg.interchangeMode} />}
        right={<LegDurationLengthMeta leg={leg} />}
      />
      {end && (
        <DetailWrapper
          bold
          left={TriasHelper.formatTimestamp(leg.timeWindowEnd)}
          right={leg.legEnd?.locationName?.text}
        />
      )}
    </>
  );
}
