import {
  AppBar,
  IconButton,
  Link as MuiLink,
  List,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  styled,
  css,
  Link as HyperLink,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link } from '@tanstack/react-router';
import { Fragment, useState } from 'react';

import ChevronUpIcon from '@/assets/icons/chevron-up-circle.svg?react';
import MailIcon from '@/assets/icons/mail-icon.svg?react';
import MenuIcon from '@/assets/icons/menu.svg?react';
import { footerLinks } from '@/components/Footer';
import { Logo } from '@/components/Logo';
import { LogoInstanceLink } from '@/components/LogoInstanceLink';
import { CONTACT_EMAIL } from '@/modules/ContactEmail';

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    display: block;
    margin-left: ${theme.spacing(2)};

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `,
) as typeof IconButton;

const StyledAppBar = styled(AppBar)(
  () => css`
    z-index: 100;
  `,
);

const StyledToolbar = styled(Toolbar)(
  () => css`
    display: flex;
    justify-content: space-between;
  `,
);

const StyledSwipeableDrawer = styled(SwipeableDrawer)(
  () => css`
    & .MuiPaper-root {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    text-transform: uppercase;
    font-weight: bold;
  `,
);

const StyledListItemText = styled(ListItemText)(
  () => css`
    text-align: center;

    & .MuiTypography-root {
      font-weight: bold;
    }
  `,
);

export function MobileNavigation() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawerOpen = () => setDrawerOpen((prevState) => !prevState);

  const theme = useTheme();
  const show = useMediaQuery(theme.breakpoints.down('md'));

  return show ? (
    <>
      <StyledAppBar position="relative" elevation={1}>
        <StyledToolbar>
          <LogoInstanceLink />

          <StyledIconButton onClick={toggleDrawerOpen} edge="end" color="inherit" aria-label="Menü öffnen">
            <MenuIcon />
          </StyledIconButton>
        </StyledToolbar>
      </StyledAppBar>

      <StyledSwipeableDrawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawerOpen}
        onOpen={toggleDrawerOpen}
        disableSwipeToOpen
      >
        <IconButton onClick={toggleDrawerOpen} sx={{ marginBottom: 3, color: 'primary.light' }}>
          <ChevronUpIcon />
        </IconButton>
        <Link to="/" style={{ marginBottom: 3 }}>
          <Logo />
        </Link>
        <List>
          {footerLinks.map(({ title, to, href }) => (
            <Fragment key={title}>
              <ListItemButton {...((to && { component: Link, to }) || (href && { component: MuiLink, href }))}>
                <StyledListItemText primary={title} />
              </ListItemButton>
            </Fragment>
          ))}
        </List>
        <IconButton component={HyperLink} href={`mailto:${CONTACT_EMAIL}`} mt={4}>
          <MailIcon width={32} height={32} />
        </IconButton>
      </StyledSwipeableDrawer>
    </>
  ) : null;
}
