import { Typography } from '@mui/material';
import { ReactNode } from 'react';

type PopupTitleProps = { children: ReactNode; marginBottom?: boolean };

export function PopupTitle({ children, marginBottom }: PopupTitleProps) {
  return children ? (
    <Typography variant="h1" mb={marginBottom ? 1 : 0}>
      {children}
    </Typography>
  ) : null;
}
