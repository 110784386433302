import { Box, styled, css } from '@mui/material';

import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { ResultStructuresList } from '@/components/result/ResultStructuresList';
import { RoutingForm } from '@/components/routing/RoutingForm';
import { RoutingModeSelect } from '@/components/routing/RoutingModeSelect';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: ${theme.spacing(50)};
      background-color: ${theme.palette.primary.main};
    }
  `,
);

const StyledRouteBox = styled(Box)(
  ({ theme }) => css`
    display: none;
    padding: ${theme.spacing(2)};

    ${theme.breakpoints.up('md')} {
      display: block;
      overflow-y: auto;
    }
  `,
);

export function RoutingSidebar() {
  return (
    <StyledBox>
      <Header />
      <StyledRouteBox>
        <RoutingForm />
        <RoutingModeSelect />
        <ResultStructuresList />
      </StyledRouteBox>
      <Footer />
    </StyledBox>
  );
}
