import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl/maplibre';

import ParkingIcon from '@/assets/icons/map/parking.svg?url';
import { useMapImages } from '@/hooks/useMapImages';
import { SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { PG_FEATURESERV_URL } from '@/setup/pg-featureserv';
import { MapIcon } from '@/types/MapIcon';
import { MapLayer } from '@/types/MapLayer';

export const PARKING_SYMBOL_COUNT_LAYER = `${MapLayer.PARKING}_SYMBOL_COUNT_LAYER`;
export const PARKING_SYMBOL_ICON_LAYER = `${MapLayer.PARKING}_SYMBOL_ICON_LAYER`;

export function ParkingLayer() {
  useMapImages({
    images: [{ name: MapIcon.PARKING, url: ParkingIcon, width: 36, height: 36 }],
  });

  const [timestamp, setTimestamp] = useState<string>(dayjs().toISOString());

  useEffect(() => {
    const interval = setInterval(
      () => {
        setTimestamp(dayjs().toISOString());
      },
      5 * 60 * 1000,
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <Source
      key={MapLayer.PARKING}
      id={MapLayer.PARKING}
      type="geojson"
      data={`${PG_FEATURESERV_URL}/collections/frontend.parking_${SATURN_INSTANCE.toLowerCase()}/items.json?timestamp=${timestamp}`}
    >
      <Layer
        key={MapLayer.PARKING}
        id={MapLayer.PARKING}
        type="circle"
        paint={{
          'circle-color': '#005a8c',
          'circle-radius': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 9, 16, 18],
        }}
      />
      <Layer
        key={PARKING_SYMBOL_COUNT_LAYER}
        id={PARKING_SYMBOL_COUNT_LAYER}
        type="symbol"
        // @ts-ignore
        filter={['==', ['typeof', ['get', 'short_term_parking_free']], 'number']}
        paint={{
          'text-color': 'white',
        }}
        layout={{
          'text-font': ['Noto Sans Bold'],
          'text-field': ['get', 'short_term_parking_free'],
          'text-size': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 7, 16, 14],
        }}
      />
      <Layer
        key={PARKING_SYMBOL_ICON_LAYER}
        id={PARKING_SYMBOL_ICON_LAYER}
        type="symbol"
        // @ts-ignore
        filter={['!=', ['typeof', ['get', 'short_term_parking_free']], 'number']}
        layout={{
          'icon-image': MapIcon.PARKING,
          'icon-size': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 0.5, 16, 1],
        }}
      />
    </Source>
  );
}
