import { TriasHelper } from '@/modules/TriasHelper';
import { ContinuousLegStructure, InterchangeLegStructure } from '@/modules/api-routing';

type LegDurationLengthMetaProps = { leg: ContinuousLegStructure | InterchangeLegStructure };

export function LegDurationLengthMeta({ leg }: LegDurationLengthMetaProps) {
  return (
    <>
      {leg.duration !== undefined && TriasHelper.formatDuration(leg.duration)}
      {leg.duration !== undefined && leg.length !== undefined && ' | '}
      {leg.length !== undefined && TriasHelper.formatDistance(leg.length)}
    </>
  );
}
