import { ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { Layer, Source } from 'react-map-gl/maplibre';

import { MapDetailCard } from '@/components/map-detail-card/MapDetailCard';
import { MapDetailIdentifier } from '@/components/popup/MapDetailIdentifier';
import { PopupDatePeriod } from '@/components/popup/PopupDatePeriod';
import { PopupTitle } from '@/components/popup/PopupTitle';
import { IS_SATURN_INSTANCE_HE } from '@/modules/SaturnInstance';
import { TrafficReport } from '@/modules/TrafficReport';
import { useUserStore } from '@/stores/useUserStore';
import { MapLayer } from '@/types/MapLayer';
import { TrafficReportFeature } from '@/types/TrafficReportFeature';

type TrafficReportPopupProps = {
  feature?: TrafficReportFeature;
  onClose: () => void;
};

export function TrafficReportDetailCard({ feature, onClose }: TrafficReportPopupProps) {
  const theme = useTheme();
  const adminMode = useUserStore((state) => state.adminMode);
  const [geomType, setGeomType] = useState<'original' | 'matched'>(
    feature?.properties.matched ? 'matched' : 'original',
  );

  if (!feature) {
    return null;
  }

  const title = TrafficReport.title(feature);

  const comments: string[] = IS_SATURN_INSTANCE_HE
    ? [feature.properties.comments?.replace('{}', '').replace('{"', '').replace('"}', '')]
    : JSON.parse(feature.properties.comments || '[]');
  const reroutingComments: string[] = IS_SATURN_INSTANCE_HE
    ? []
    : JSON.parse(feature.properties.rerouting_comments || '[]');

  const geoms = {
    original: feature.properties.originalGeometry || feature.properties.geom_original || '',
    matched: feature.properties.geom_matched || '',
  };

  return (
    <>
      <MapDetailCard onClose={onClose}>
        <PopupTitle>{title}</PopupTitle>
        <MapDetailIdentifier feature={feature} />
        {adminMode && geoms.original && geoms.matched && (
          <ToggleButtonGroup
            color="primary"
            size="small"
            value={geomType}
            exclusive
            onChange={(event, value) => {
              if (value) {
                setGeomType(value);
              }
            }}
            sx={{ mb: 2 }}
          >
            <ToggleButton value="original">Original</ToggleButton>
            <ToggleButton value="matched">Matched</ToggleButton>
          </ToggleButtonGroup>
        )}

        <PopupDatePeriod feature={feature} />

        {comments && comments.map((comment) => <Typography key={comment}>{comment}</Typography>)}
        {reroutingComments.length > 0 && (
          <>
            <Typography variant="h2">Umleitung</Typography>
            {reroutingComments.map((comment) => (
              <Typography key={comment}>{comment}</Typography>
            ))}
          </>
        )}
      </MapDetailCard>

      {geoms[geomType] && (
        <Source type="geojson" data={JSON.parse(geoms[geomType])}>
          <Layer
            beforeId={MapLayer.TRAFFIC_REPORT}
            type="line"
            layout={{
              'line-cap': 'round',
              'line-join': 'round',
            }}
            paint={{
              'line-color': theme.palette.error.main,
              'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 2, 14, 4, 18, 8],
              'line-dasharray': [1, 2],
            }}
          />
        </Source>
      )}
    </>
  );
}
