import { Box, Collapse, css, Grid, IconButton, styled, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

import ChevronDownIcon from '@/assets/icons/chevron-down-circle.svg?react';
import { ResultStructureDetails } from '@/components/result/ResultStructureDetails';
import { ResultStructureSummary } from '@/components/result/ResultStructureSummary';
import { ModeIcon } from '@/components/routing/ModeIcon';
import { ModeGuard } from '@/modules/ModeGuard';
import { TriasHelper } from '@/modules/TriasHelper';
import { RouteResultStructure, TripLegStructure, TripResultStructure } from '@/modules/api-routing';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { Mode } from '@/types/Mode';
import { transientOptions } from '@/utils/transient-options';

const StyledIconButton = styled(
  IconButton,
  transientOptions,
)<{ $open?: boolean }>(
  ({ theme, $open }) => css`
    transform: ${!$open && 'rotate(-90deg)'};
    color: ${theme.palette.primary.light};
  `,
);

const StrategyCircle = styled(
  Box,
  transientOptions,
)<{ $open?: boolean }>(
  ({ theme, $open }) => css`
    position: absolute;
    background-color: ${theme.palette.error.main};
    width: ${theme.spacing(1.5)};
    height: ${theme.spacing(1.5)};
    top: 40px;
    right: ${theme.spacing(5)};
    left: ${!$open ? theme.spacing(5) : theme.spacing(4.5)};
    border: none;
    border-radius: 6px;
  `,
);

type ResultStructureCollapseProps = {
  handleDetails?: () => void;
  structureIndex: number;
} & (
  | {
      mode: Mode.WALK | Mode.CYCLE | Mode.SELF_DRIVE_CAR;
      resultStructure: RouteResultStructure;
    }
  | {
      mode: Mode.PUBLIC_TRANSPORT | Mode.BIKE_AND_RIDE | Mode.PARK_AND_RIDE;
      resultStructure: TripResultStructure;
    }
);

export function ResultStructureCollapse({
  mode,
  resultStructure,
  structureIndex,
  handleDetails,
}: ResultStructureCollapseProps) {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined | string>('');
  const [open, setOpen] = useState(false);
  const selectedMode = useRoutesStore((state) => state.selectedMode);
  const { updateSelectedMode } = useRoutesStore((state) => state.actions);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  if (!resultStructure) {
    return null;
  }

  const hasStrategies =
    mode === Mode.SELF_DRIVE_CAR && TriasHelper.getIndividualRouteStructureStrategies(resultStructure).length > 0;

  const handleClick = (clickIndex: number | undefined | string) => {
    if (selectedIndex === clickIndex) {
      setSelectedIndex('');
    } else {
      setSelectedIndex(clickIndex);
    }
  };

  return (
    <Collapse
      sx={{ marginBottom: theme.spacing(1.5) }}
      in={structureIndex === selectedIndex}
      collapsedSize={72}
      orientation="vertical"
    >
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        gap={2}
        sx={{
          border: 'none',
          marginBottom: 2,
        }}
      >
        <Grid item>
          {selectedMode.mode && selectedMode.structureIndex === structureIndex ? (
            <ModeIcon variant="circle-filled" mode={selectedMode.mode} />
          ) : (
            <ModeIcon variant="circle-border" mode={selectedMode.mode} />
          )}
        </Grid>
        <Grid item sx={{ textAlign: 'left', minWidth: 0 }}>
          {ModeGuard.isIndividualMode(mode) && ModeGuard.isIndividualResult(mode, resultStructure) && (
            <ResultStructureSummary
              mode={mode}
              structureIndex={structureIndex}
              duration={resultStructure?.route?.duration}
              departure={resultStructure?.route?.startTime}
              distance={resultStructure?.route?.distance}
              hasStrategies={hasStrategies}
              algorithm={
                resultStructure?.route?.extension?.any.value.modeMetaInfo[0].tripModeAlgorithm.individualAlgorithm[0]
              }
            />
          )}

          {ModeGuard.isIntermodalMode(mode) && ModeGuard.isIntermodalResult(mode, resultStructure) && (
            <ResultStructureSummary
              mode={mode}
              structureIndex={structureIndex}
              duration={resultStructure?.trip?.duration}
              departure={resultStructure?.trip?.startTime}
              arrival={resultStructure?.trip?.endTime}
              interchange={resultStructure?.trip?.interchanges}
              changeModes={resultStructure?.trip?.tripLeg
                ?.filter((timedLeg: TripLegStructure) => timedLeg.timedLeg?.service?.publishedLineName?.text)
                .map((timedLeg: TripLegStructure) => timedLeg?.timedLeg?.service?.publishedLineName?.text)}
            />
          )}
        </Grid>
        <Grid item marginLeft="auto">
          <StyledIconButton
            onClick={() => {
              updateSelectedMode({ structureIndex });

              handleClick(structureIndex);

              setOpen(!open);

              if (matches && handleDetails) {
                handleDetails();
              }
            }}
            $open={open}
          >
            {hasStrategies && selectedMode.mode === Mode.SELF_DRIVE_CAR && <StrategyCircle $open={open} />}
            <ChevronDownIcon />
          </StyledIconButton>
        </Grid>
      </Grid>

      <ResultStructureDetails resultStructure={resultStructure} />
    </Collapse>
  );
}
