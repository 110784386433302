import {
  Typography,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Link as MuiLink,
  styled,
  css,
} from '@mui/material';
import { Link } from '@tanstack/react-router';

import { Logo } from '@/components/Logo';
import { IS_SATURN_INSTANCE_HE, SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { useUserStore } from '@/stores/useUserStore';
import { SaturnInstance } from '@/types/SaturnInstance';

const StyledDialog = styled(MuiDialog)(
  ({ theme }) => css`
    & .MuiDialog-paper {
      border-radius: ${theme.spacing(3)};
      background-color: ${theme.palette.primary.main};
    }

    text-align: center;
  `,
);

const StyledButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
    text-decoration: none;
    font-weight: bold;
    text-transform: none;
    background-color: white;
    border-radius: 24px;

    &.MuiButtonBase-root:hover {
      background-color: ${theme.palette.common.white};
      opacity: 0.5;
    }
  `,
);

const StyledLink = styled(MuiLink)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    text-decoration: none;
    font-weight: bold;
  `,
) as typeof MuiLink;

export function Dialog() {
  const dialogVisible = useUserStore((state) => state.dialogVisible);

  const { setDialogVisible } = useUserStore((state) => state.actions);

  const handleClose = (event?: HTMLButtonElement | undefined, reason?: string) => {
    if (reason !== 'backdropClick') {
      setDialogVisible(false);
    }
  };

  return (
    <StyledDialog maxWidth="sm" open={dialogVisible}>
      <DialogTitle component="div">
        <Box mb={2} sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
          <Logo />
        </Box>
        <Typography variant="h1" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }} mb={2}>
          {IS_SATURN_INSTANCE_HE
            ? 'Herzlich Willkommen auf dem Webportal für Alltagsmobilität in Frankfurt RheinMain!'
            : 'Herzlich Willkommen am SATURN Forschungsdemonstrator!'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography mb={2}>
          {
            {
              [SaturnInstance.BW]:
                'Dieses Webportal ist ein intermodales Auskunftssystem für die Region Stuttgart, welches als Demonstrator Anwendung im Projekt SATURN umgesetzt wurde.',
              [SaturnInstance.BY]:
                'Dieses Webportal ist ein intermodales Auskunftssystem für die Region München Oberland, welches als Demonstrator Anwendung im Projekt SATURN umgesetzt wurde.',
              [SaturnInstance.HE]:
                'Vielmobil ist ein intermodales Auskunftssystem für die Region Frankfurt RheinMain. Die Berücksichtigung von stadt- und umweltsensitiven Verkehrslenkungsmaßnahmen befindet sich derzeit im Testbetrieb.',
              [SaturnInstance.DE]:
                'Dieses Webportal ist ein intermodales Auskunftssystem mit verteilter Routenberechnung für die länderübergreifende Pilotregion Frankfurt RheinMain - Stuttgart - München Oberland, welches als Demonstrator Anwendung im Projekt SATURN umgesetzt wurde.',
            }[SATURN_INSTANCE]
          }
          <br />
          {/* @ts-ignore */}
          <StyledLink component={Link} to="/ueber-das-projekt">
            Mehr erfahren
          </StyledLink>
        </Typography>

        <StyledButton size="large" onClick={() => handleClose()}>
          Weiter
        </StyledButton>
      </DialogContent>
    </StyledDialog>
  );
}
