import { Stack } from '@mui/material';

import SaturnLogo from '@/assets/logos/saturn.svg?react';
import VielmobilLogo from '@/assets/logos/vielmobil.svg?react';
import { IS_SATURN_INSTANCE_HE } from '@/modules/SaturnInstance';

export function Logo() {
  return (
    <Stack direction="row" spacing={2}>
      <SaturnLogo title="Saturn" style={{ display: 'block', width: 'auto', height: 32 }} />
      {IS_SATURN_INSTANCE_HE && (
        <VielmobilLogo title="Vielmobil" style={{ display: 'block', width: 'auto', height: 32 }} />
      )}
    </Stack>
  );
}
