import { Box, styled, css, Link, Button } from '@mui/material';
import dayjs from 'dayjs';
import { Fragment } from 'react';

import { ContinuousLegDetail } from '@/components/result/ContinuousLegDetail';
import { DetailWrapper } from '@/components/result/DetailWrapper';
import { InterchangeLegDetail } from '@/components/result/InterchangeLegDetail';
import { LegDurationLengthMeta } from '@/components/result/LegDurationLengthMeta';
import { StrategyDetails } from '@/components/result/StrategyDetails';
import { TimedLegDetail } from '@/components/result/TimedLegDetail';
import { ModeIcon } from '@/components/routing/ModeIcon';
import { useRoutes } from '@/hooks/useRoutes';
import { ModeGuard } from '@/modules/ModeGuard';
import { IS_SATURN_INSTANCE_HE, SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { TriasHelper } from '@/modules/TriasHelper';
import { Duration, RouteResultStructure, TripResultStructure } from '@/modules/api-routing';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { Mode } from '@/types/Mode';
import { RouteLocationType } from '@/types/RouteLocationType';
import { SaturnInstance } from '@/types/SaturnInstance';

const manipulateDuration = (duration: Duration | undefined, mode: Mode): Duration | undefined => {
  if (mode === Mode.WALK) {
    const minutes = dayjs.duration(duration || {}).asMinutes() * 3;

    return dayjs.duration({ minutes }).toISOString() as Duration;
  }

  return duration;
};

const manipulateArrival = (duration: Duration | undefined, startTime: string | undefined, mode: Mode): string =>
  dayjs(startTime)
    .add(dayjs.duration(manipulateDuration(duration, mode) || {}))
    .toISOString();

const DetailsButtonWrapper = styled(Box)(
  ({ theme }) => css`
    text-align: center;
    margin-top: ${theme.spacing(2)};
  }
  `,
);

const DetailsButton = styled(Button)(
  ({ theme }) => css`
		&.MuiButtonBase-root:hover {
			background-color: ${theme.palette.common.white};
			color: ${theme.palette.primary.main};
		  }
		}
		`,
) as typeof Link;

type ResultStructureDetailsWrapperProps = {
  resultStructure: RouteResultStructure | TripResultStructure;
  mobile?: boolean;
};

export function ResultStructureDetails({ resultStructure, mobile }: ResultStructureDetailsWrapperProps) {
  const selectedMode = useRoutesStore((state) => state.selectedMode);
  const origin = useRoutesStore((state) => state.routeLocations[RouteLocationType.ORIGIN]);
  const destination = useRoutesStore((state) => state.routeLocations[RouteLocationType.DESTINATION]);
  const date = useRoutesStore((state) => state.time.date);

  const routes = useRoutes();

  if (!selectedMode.mode) {
    return null;
  }

  const formattedDate = dayjs(date).format('DD.MM.YYYY');

  const strategies =
    TriasHelper.getIndividualRouteStructureStrategies(resultStructure).length > 0
      ? routes[selectedMode.mode]?.data?.serviceDelivery?.deliveryPayload?.individualRouteResponse
          ?.individualRouteResponseContext?.situations?.roadSituation
      : undefined;

  return (
    <Box sx={{ marginTop: mobile ? 2 : 0 }}>
      {ModeGuard.isIndividualResult(selectedMode.mode, resultStructure) && (
        <>
          <>
            <DetailWrapper
              bold
              left={TriasHelper.formatTimestamp(resultStructure?.route?.startTime)}
              right={origin?.properties.name.full}
            />
            <DetailWrapper
              left={<ModeIcon mode={selectedMode.mode} />}
              right={
                <LegDurationLengthMeta
                  leg={{ duration: resultStructure?.route?.duration, length: resultStructure?.route?.distance }}
                />
              }
            />
          </>

          <StrategyDetails strategies={strategies} />

          <DetailWrapper
            bold
            left={TriasHelper.formatTimestamp(
              manipulateArrival(resultStructure?.route?.duration, resultStructure?.route?.startTime, selectedMode.mode),
            )}
            right={destination?.properties.name.full}
          />

          {IS_SATURN_INSTANCE_HE && selectedMode.mode === Mode.CYCLE && (
            <DetailsButtonWrapper>
              <DetailsButton
                component={Link}
                href={`https://radroutenplaner.hessen.de/map/?sharelink=1&lng=&prm=1&spt=${origin?.geometry.coordinates[0]},${origin?.geometry.coordinates[1]}&ept=${destination?.geometry.coordinates[0]},${destination?.geometry.coordinates[1]}&tre=false&date=${formattedDate}&bev=EntireBicycleNetwork&stg=No&oa=&on=&ow=&ou=&oun=&fer=false&mtb=true`}
                underline="none"
                target="_blank"
                referrerPolicy="no-referrer"
              >
                Auskunft
              </DetailsButton>
            </DetailsButtonWrapper>
          )}
        </>
      )}

      {ModeGuard.isIntermodalResult(selectedMode.mode, resultStructure) &&
        resultStructure.trip?.tripLeg?.map((tripLeg, index, legs) => {
          const tripLegLength = resultStructure?.trip?.tripLeg?.length || 0;

          if (tripLeg.continuousLeg) {
            return (
              <ContinuousLegDetail
                key={tripLeg.legId}
                leg={tripLeg.continuousLeg}
                start={index === 0 || !!legs[index - 1].continuousLeg || !!legs[index - 1].interchangeLeg}
                end={index === tripLegLength - 1}
              />
            );
          }

          if (tripLeg.interchangeLeg) {
            return (
              <InterchangeLegDetail
                key={tripLeg.legId}
                leg={tripLeg.interchangeLeg}
                start={index === 0 || !!legs[index - 1].continuousLeg || !!legs[index - 1].interchangeLeg}
                end={index === tripLegLength - 1}
              />
            );
          }

          if (tripLeg.timedLeg) {
            return (
              <Fragment key={tripLeg.legId}>
                {index !== 0 && legs[index - 1].timedLeg && (
                  <DetailWrapper left={<ModeIcon mode={Mode.WALK} />} right="Umstieg" />
                )}

                <TimedLegDetail leg={tripLeg.timedLeg} />
              </Fragment>
            );
          }

          return null;
        })}

      {selectedMode.mode === Mode.PUBLIC_TRANSPORT && (
        <DetailsButtonWrapper>
          <DetailsButton
            component={Link}
            href={
              {
                [SaturnInstance.BW]: 'https://www.bwegt.de/ihr-nahverkehr/reiseinformationen/efa/app',
                [SaturnInstance.BY]: 'https://www.bayern-fahrplan.de/de/auskunft',
                [SaturnInstance.DE]: 'https://reiseauskunft.bahn.de/bin/query.exe/dn',
                [SaturnInstance.HE]: 'https://www.rmv.de/auskunft/bin/jp/query.exe/dn',
              }[SATURN_INSTANCE]
            }
            target="_blank"
            referrerPolicy="no-referrer"
          >
            Auskunft
          </DetailsButton>
        </DetailsButtonWrapper>
      )}
    </Box>
  );
}
