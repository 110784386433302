import { Box, css, styled } from '@mui/material';

import { TriasHelper } from '@/modules/TriasHelper';
import { ServiceArrival, ServiceDeparture } from '@/modules/api-routing';
import { transientOptions } from '@/utils/transient-options';

const Time = styled(Box)(
  ({ theme }) => css`
    display: inline-flex;
    padding: 0 ${theme.spacing(0.25)};
  `,
);

const EstimatedTime = styled(
  Time,
  transientOptions,
)<{ $mode: 'default' | 'delayed' }>(
  ({ theme, $mode }) => css`
    display: inline-flex;
    background-color: ${theme.palette.common.white};
    color: ${theme.palette.primary.main};
    border-radius: ${theme.spacing(0.25)};
    padding: 0 ${theme.spacing(0.25)};

    ${$mode === 'delayed' &&
    css`
      color: ${theme.palette.error.main};
    `};
  `,
);

type ServiceTimeMetaProps = { serviceTime?: ServiceArrival | ServiceDeparture };

export function ServiceTimeMeta({ serviceTime }: ServiceTimeMetaProps) {
  return (
    serviceTime &&
    serviceTime.timetabledTime && (
      <>
        <Time>{TriasHelper.formatTimestamp(serviceTime.timetabledTime)}</Time>

        {serviceTime.estimatedTime && (
          <>
            <br />
            <EstimatedTime
              $mode={serviceTime.timetabledTime.localeCompare(serviceTime.estimatedTime) < 0 ? 'delayed' : 'default'}
            >
              {TriasHelper.formatTimestamp(serviceTime.estimatedTime)}
            </EstimatedTime>
          </>
        )}
      </>
    )
  );
}
