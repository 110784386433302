import { LineString } from 'geojson';

import { ModeGuard } from '@/modules/ModeGuard';
import { TriasHelper } from '@/modules/TriasHelper';
import {
  ContinuousLegStructure,
  GeoPositionStructure,
  InterchangeLegStructure,
  NavigationSectionStructure,
  RouteStructure,
  StopPointRefStructure,
  TrackSectionStructure,
  TripStructure,
} from '@/modules/api-routing';
import { Mode } from '@/types/Mode';
import { RouteLineType } from '@/types/RouteLineType';
import { RouteSegmentFeature, RouteSegmentsFeatureCollection } from '@/types/RouteSegmentFeature';
import { TripResponseContext } from '@/types/Trias';
import { UseRoutesResult } from '@/types/UseRoutesResult';
import { isNonNullable } from '@/utils/isNonNullable';

export class TriasRouteSegments {
  public static get(routes: UseRoutesResult, { mode, structureIndex }: { mode: Mode; structureIndex: number }) {
    if (mode === Mode.SELF_DRIVE_CAR) {
      return TriasRouteSegments.getCar(TriasHelper.getIndividualRouteStructure(routes[mode]?.data, structureIndex));
    }

    if (ModeGuard.isIntermodalMode(mode)) {
      return TriasRouteSegments.getIntermodal(
        TriasHelper.getIntermodalTripStructure(routes[mode]?.data, structureIndex),
      );
    }

    return TriasRouteSegments.getIndividual(
      TriasHelper.getIndividualRouteStructure(routes[mode]?.data, structureIndex),
    );
  }

  private static getIndividual(route: RouteStructure | undefined) {
    const featureCollection: RouteSegmentsFeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    if (route === undefined) return featureCollection;

    route.routeLeg?.forEach((leg) => {
      const lineString: LineString = {
        type: 'LineString',
        coordinates: [],
      };

      leg.legTrack?.trackSection?.forEach((section) => {
        section.projection?.position?.forEach((pos) => {
          lineString.coordinates.push([pos.longitude as number, pos.latitude as number]);
        });
      });
      const feature: RouteSegmentFeature = {
        type: 'Feature',
        geometry: lineString,
        properties: { routeLineType: RouteLineType.INDIVIDUAL },
      };

      featureCollection.features.push(feature);
    });

    return featureCollection;
  }

  private static getCar(route: RouteStructure | undefined) {
    const featureCollection: RouteSegmentsFeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    if (route === undefined) return featureCollection;

    const alternativeSegments =
      route?.routeLeg
        ?.map((leg) => leg.legTrack?.trackSection?.[0].extension?.any?.value?.segmentReferences?.segmentReference)
        .flat()
        .filter(isNonNullable)
        .filter((projectIndex: any) =>
          projectIndex.unspecifiedInformationByKeyValue?.map?.mapItem?.find(
            (item: any) => item.key === 'TrafficManagement',
          ),
        ) || [];

    const alternativeSections = (
      alternativeSegments.map(
        (coordinate: { projectionPositionIndexStart: number; projectionPositionIndexStop: number }) => ({
          start: coordinate.projectionPositionIndexStart,
          stop: coordinate.projectionPositionIndexStop,
        }),
      ) as { start: number; stop: number }[]
    )
      .sort(({ start: startA }, { start: startB }) => startA - startB)
      .reduce(
        (sections, section) => {
          if (sections[sections.length - 1] && sections[sections.length - 1].stop === section.start) {
            // eslint-disable-next-line no-param-reassign
            sections[sections.length - 1].stop = section.stop;
          } else {
            sections.push(section);
          }

          return sections;
        },
        [] as { start: number; stop: number }[],
      );

    let currentIndex = 0;
    const segments = route?.routeLeg
      ?.map((leg) => leg.legTrack?.trackSection?.[0].projection?.position)
      .flat()
      .filter(isNonNullable);

    if (segments) {
      alternativeSections.forEach((section) => {
        if (currentIndex !== section.start) {
          featureCollection.features.push(
            TriasRouteSegments.getSegmentSlice(segments, currentIndex, section.start + 1, RouteLineType.INDIVIDUAL),
          );
          currentIndex = section.start;
        }

        featureCollection.features.push(
          TriasRouteSegments.getSegmentSlice(
            segments,
            section.start,
            section.stop + 1,
            RouteLineType.STRATEGY_PREFERRED,
          ),
        );
        currentIndex = section.stop;
      });

      if (currentIndex !== segments.length) {
        featureCollection.features.push(
          TriasRouteSegments.getSegmentSlice(segments, currentIndex, segments.length, RouteLineType.INDIVIDUAL),
        );
      }
    }

    return featureCollection;
  }

  private static getIntermodal(route: TripStructure | undefined) {
    return (route?.tripLeg || []).reduce<RouteSegmentsFeatureCollection>(
      (featureCollection, leg) => {
        let coordinates: number[][] | undefined;
        let routeLineType: RouteLineType | undefined;

        if (leg.continuousLeg) {
          routeLineType = RouteLineType.INDIVIDUAL;

          if (leg.continuousLeg.legTrack?.trackSection) {
            coordinates = TriasRouteSegments.projectionToCoordinates(leg.continuousLeg.legTrack.trackSection);
          } else {
            coordinates = TriasRouteSegments.legStartLegEndToCoordinates(leg.continuousLeg);
          }
        }

        if (leg.timedLeg) {
          routeLineType = RouteLineType.PUBLIC_TRANSPORT;

          if (leg.timedLeg.legTrack?.trackSection) {
            coordinates = TriasRouteSegments.projectionToCoordinates(leg.timedLeg.legTrack.trackSection);
          }
        }

        if (leg.interchangeLeg) {
          routeLineType = RouteLineType.INDIVIDUAL;

          if (leg.interchangeLeg.navigationPath?.navigationSection) {
            coordinates = TriasRouteSegments.projectionToCoordinates(
              leg.interchangeLeg.navigationPath.navigationSection,
            );
          } else {
            coordinates = TriasRouteSegments.legStartLegEndToCoordinates(leg.interchangeLeg);
          }
        }

        if (routeLineType && coordinates && coordinates.length >= 2) {
          featureCollection.features.push({
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates,
            },
            properties: {
              routeLineType,
            },
          });
        }

        return featureCollection;
      },
      {
        type: 'FeatureCollection',
        features: [],
      },
    );
  }

  private static getSegmentSlice(
    segments: GeoPositionStructure[],
    start: number,
    end: number,
    routeLineType: RouteLineType,
  ): RouteSegmentFeature {
    return {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: segments
          .slice(start, end)
          .map(({ longitude, latitude }) => [longitude as number, latitude as number]),
      },
      properties: { routeLineType },
    };
  }

  private static projectionToCoordinates(trackSection: (TrackSectionStructure | NavigationSectionStructure)[]) {
    return trackSection
      .map((section) =>
        (
          ('projection' in section && section.projection?.position) ||
          ('trackSection' in section && section.trackSection?.projection?.position) ||
          []
        ).map((position) => [position.longitude as number, position.latitude as number]),
      )
      .flat();
  }

  private static legStartLegEndToCoordinates(legStructure: ContinuousLegStructure | InterchangeLegStructure) {
    const start = legStructure.legStart?.geoPosition;
    const end = legStructure.legEnd?.geoPosition;

    return TriasRouteSegments.geoPositionsToCoordinates([start, end]);
  }

  // @ts-ignore
  private static getStopPointRef(stopPointRef: StopPointRefStructure, tripResponseContext: TripResponseContext) {
    return (
      stopPointRef.value &&
      tripResponseContext.locations?.location?.find(
        (location) => location?.stopPoint?.stopPointRef?.value === stopPointRef.value,
      )
    );
  }

  private static geoPositionsToCoordinates(geoPositions: (GeoPositionStructure | undefined)[]) {
    return geoPositions
      .filter(
        (geoPosition): geoPosition is Required<Pick<GeoPositionStructure, 'longitude' | 'latitude'>> =>
          typeof geoPosition?.longitude === 'number' && typeof geoPosition?.latitude === 'number',
      )
      .map(({ longitude, latitude }) => [longitude, latitude]);
  }
}
