import { Layer, Source } from 'react-map-gl/maplibre';

import TrafficInfoSignIcon from '@/assets/icons/map/traffic-report/traffic-info-sign.svg?url';
import { useMapImages } from '@/hooks/useMapImages';
import { useMissingMapImages } from '@/hooks/useMissingMapImages';
import { GeoServer } from '@/modules/GeoServer';
import { ICON_SIZE } from '@/modules/IconSize';
import { IS_SATURN_INSTANCE_HE, SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { MARTIN_URL } from '@/setup/martin';
import { MapIcon } from '@/types/MapIcon';
import { MapLayer } from '@/types/MapLayer';

export const SIGNS_CIRCLE_LAYER = `${MapLayer.SIGNS}_CIRCLE_LAYER`;
export const SIGNS_PICTOGRAM_LAYER = `${MapLayer.SIGNS}_PICTOGRAM_LAYER`;

export function SignLayers() {
  useMapImages({
    images: [{ name: MapIcon.TRAFFIC_INFO_SIGN, url: TrafficInfoSignIcon, width: 36, height: 36 }],
  });
  useMissingMapImages();

  return (
    <Source
      key={MapLayer.SIGNS}
      id={MapLayer.SIGNS}
      {...(IS_SATURN_INSTANCE_HE
        ? {
            type: 'geojson',
            data: GeoServer.wfs({ typeName: 'signs_view' }),
            filter: ['to-boolean', ['get', 'content']],
          }
        : {
            type: 'vector',
            url: `${MARTIN_URL}/sign_${SATURN_INSTANCE.toLowerCase()}`,
          })}
    >
      <Layer
        key={MapLayer.SIGNS}
        id={MapLayer.SIGNS}
        type="symbol"
        source-layer={`sign_${SATURN_INSTANCE.toLowerCase()}`}
        filter={['!', ['has', 'pictogram_enum']]}
        layout={{
          'icon-size': ICON_SIZE,
          'icon-allow-overlap': true,
          'icon-image': MapIcon.TRAFFIC_INFO_SIGN,
        }}
      />

      <Layer
        key={SIGNS_CIRCLE_LAYER}
        id={SIGNS_CIRCLE_LAYER}
        type="circle"
        source-layer="sign_by"
        filter={['has', 'pictogram_enum']}
        paint={{ 'circle-radius': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 9, 16, 18] }}
      />
      <Layer
        key={SIGNS_PICTOGRAM_LAYER}
        id={SIGNS_PICTOGRAM_LAYER}
        type="symbol"
        source-layer="sign_by"
        filter={['has', 'pictogram_enum']}
        layout={{
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 0.375, 16, 0.75],
          'icon-image': ['get', 'display_url'],
        }}
      />
    </Source>
  );
}
