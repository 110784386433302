import { IS_SATURN_INSTANCE_HE } from '@/modules/SaturnInstance';

const LEVEL_OF_SERVICE_COLORS_SATURN = [
  { value: 5, color: '#cc292e' },
  { value: 4, color: '#e6996a' },
  { value: 3, color: '#e6bf6a' },
  { value: 2, color: '#c1c676' },
  { value: 1, color: '#9ccc81' },
];

const LEVEL_OF_SERVICE_COLORS_HE = [
  { value: 0, color: '#cc292e' },
  { value: 1, color: '#e6996a' },
  { value: 2, color: '#e6bf6a' },
  { value: 3, color: '#9ccc81' },
];

export const LEVEL_OF_SERVICE_COLORS = IS_SATURN_INSTANCE_HE
  ? LEVEL_OF_SERVICE_COLORS_HE
  : LEVEL_OF_SERVICE_COLORS_SATURN;
