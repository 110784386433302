import dayjs from 'dayjs';

import { ModeGuard } from '@/modules/ModeGuard';
import { SATURN_INSTANCE } from '@/modules/SaturnInstance';
import {
  IndividualTransportOptionsStructure,
  IndividualTripParamStructure,
  LocationRefStructure,
} from '@/modules/api-routing';
import { LocationFeature } from '@/types/LocationFeature';
import { Mode } from '@/types/Mode';
import { RoutesState } from '@/types/RoutesState';
import { SaturnInstance } from '@/types/SaturnInstance';
import { TimeType } from '@/types/TimeType';
import { Trias } from '@/types/Trias';

export class TriasRequest {
  public static make({
    origin,
    destination,
    mode,
    time,
    algorithm,
  }: {
    origin: LocationFeature;
    destination: LocationFeature;
    time: RoutesState['time'];
  } & (
    | { mode: Exclude<Mode, Mode.SELF_DRIVE_CAR>; algorithm?: undefined }
    | { mode: Mode.SELF_DRIVE_CAR; algorithm: IndividualTripParamStructure.algorithmType }
  )): Trias {
    const originContextStructure = {
      locationRef: TriasRequest.getLocationRef(origin),
      ...(time.type === TimeType.DEPARTURE && { depArrTime: time.date.toISOString() }),
    };

    const destinationContextStructure = {
      locationRef: TriasRequest.getLocationRef(destination),
      ...(time.type === TimeType.ARRIVAL && { depArrTime: time.date.toISOString() }),
    };

    return {
      serviceRequest: {
        messageIdentifier: {
          value: `urn:trafficon:route:${mode}${algorithm ? `:${algorithm}` : ''}:${dayjs().unix()}`,
        },
        requestTimestamp: time.type === TimeType.DEPARTURE ? time.date.toISOString() : dayjs().toISOString(),
        requestorRef: {
          value: {
            [SaturnInstance.BW]: 'saturn:stuttgart:webclient',
            [SaturnInstance.BY]: 'saturn:bayern:webclient',
            [SaturnInstance.HE]: 'vielmobil:webclient',
            [SaturnInstance.DE]: 'saturn:webclient',
          }[SATURN_INSTANCE],
        },
        address: window.location.href,
        dataVersion: 'TriasExtensionTrafficon:2.8',
        language: 'de',
        serviceRequestContext: {},

        requestPayload: {
          ...(ModeGuard.isIndividualMode(mode) && {
            individualRouteRequest: {
              origin: originContextStructure,
              destination: destinationContextStructure,

              mode: [
                {
                  mode: {
                    [Mode.WALK]: IndividualTransportOptionsStructure.mode.WALK,
                    [Mode.CYCLE]: IndividualTransportOptionsStructure.mode.CYCLE,
                    [Mode.SELF_DRIVE_CAR]: IndividualTransportOptionsStructure.mode.SELF_DRIVE_CAR,
                  }[mode],
                  maxDistance: { [Mode.WALK]: 10000, [Mode.CYCLE]: 50000, [Mode.SELF_DRIVE_CAR]: 1000000 }[mode],
                },
              ],
              params: {
                algorithmType:
                  mode === Mode.SELF_DRIVE_CAR ? algorithm : IndividualTripParamStructure.algorithmType.OPTIMAL,
                banMotorways: false,
                banTollRoads: false,
                banFerries: true,
                allowUnpavedRoads: true,
                includeTrackSections: true,
                includeLegProjection: true,
              },
            },
          }),

          ...(ModeGuard.isIntermodalMode(mode) && {
            tripRequest: {
              origin: [originContextStructure],
              destination: [destinationContextStructure],

              params: {
                ptModeFilter: {
                  exclude: false,
                  ptMode: [
                    'BUS',
                    'TROLLEY_BUS',
                    'COACH',
                    'RAIL',
                    'INTERCITY_RAIL',
                    'URBAN_RAIL',
                    'METRO',
                    'TRAM',
                    'CABLEWAY',
                    'FUNICULAR',
                  ],
                },
                walkSpeed: 100,
                numberOfResults: 3,
                interchangeLimit: 10,
                itModesToCover: ['WALK'],
                includeTrackSections: true,
                includeLegProjection: true,
                includeTurnDescription: true,
                includeIntermediateStops: true,

                extension: {
                  any: {
                    name: '{http://www.vdv.de/trias/extension/trafficon}ExtendedTripParameters',
                    declaredType: 'eu.trafficon.bo.trias.extension.ExtendedTripParametersStructure',
                    scope: 'javax.xml.bind.JAXBElement$GlobalScope',
                    schemaVersion: 2.8,
                    value: {
                      intermodalTripAlgorithm: {
                        intermodalAlgorithm: ['FASTEST'],
                      },
                      globalIndividualTransportOptions: {
                        mode: 'WALK',
                        maxDistance: 100000,
                      },

                      ...([Mode.BIKE_AND_RIDE, Mode.PARK_AND_RIDE].includes(mode) && {
                        individualTripLegRequest: [
                          {
                            origin: originContextStructure,
                            destination: {
                              locationRef: {
                                pointOfInterestRef: {
                                  value: 'NULL',
                                },
                                locationName: {
                                  text: 'NULL',
                                },
                              },
                            },
                            mode: [
                              {
                                mode: mode === Mode.BIKE_AND_RIDE ? 'CYCLE' : 'SELF_DRIVE_CAR',
                              },
                            ],
                            params: {
                              includeTrackSections: true,
                              includeLegProjection: true,
                              extension: {
                                any: {
                                  name: '{http://www.vdv.de/trias/extension/trafficon}ExtendedTripLegParameters',
                                  declaredType: 'eu.trafficon.bo.trias.extension.ExtendedTripLegParametersStructure',
                                  scope: 'javax.xml.bind.JAXBElement$GlobalScope',
                                  value: {
                                    tripLegRequestUseCaseType: {
                                      value: 'VEHICLE_TO_UNKNOWN_PARK_AND_RIDE',
                                    },
                                    tripLegRequestModeContext: [
                                      {
                                        tripLegIndividualTransportOptions: {
                                          mode: mode === Mode.BIKE_AND_RIDE ? 'CYCLE' : 'SELF_DRIVE_CAR',
                                          maxDistance: mode === Mode.BIKE_AND_RIDE ? 5000 : 100000,
                                          speed: 100,
                                        },
                                        tripLegRequestAlgorithm: {
                                          individualAlgorithm: [mode === Mode.BIKE_AND_RIDE ? 'OPTIMAL' : 'FASTEST'],
                                        },
                                        tripLegIndividualTripParams: {
                                          numberOfResults: 1,
                                          banMotorways: false,
                                          banTollRoads: false,
                                          banFerries: true,
                                          allowUnpavedRoads: true,
                                        },
                                      },
                                    ],
                                  },
                                  nil: false,
                                  globalScope: true,
                                  typeSubstituted: false,
                                },
                              },
                            },
                          },
                        ],
                      }),
                    },
                    nil: false,
                    globalScope: true,
                    typeSubstituted: false,
                  },
                },
              },
            },
          }),
        },
      },
      version: '1.1',
    };
  }

  private static getLocationRef(location: LocationFeature): LocationRefStructure {
    return {
      geoPosition: { longitude: location.geometry.coordinates[0], latitude: location.geometry.coordinates[1] },
      locationName: { text: location.properties.name.full, language: 'de' },
    };
  }
}
