import { DataDrivenPropertyValueSpecification } from 'maplibre-gl';

export const ICON_SIZE: DataDrivenPropertyValueSpecification<number> = [
  'interpolate',
  ['exponential', 0.5],
  ['zoom'],
  10,
  0.5,
  16,
  1,
];
