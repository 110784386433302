import { SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { OpenAPI as OpenAPIGeocoder } from '@/modules/api-geocoder';
import { OpenAPI as OpenAPIRouting } from '@/modules/api-routing';
import { SaturnInstance } from '@/types/SaturnInstance';

const API_ORIGIN =
  {
    [SaturnInstance.BW]: import.meta.env.VITE_API_ORIGIN_BW,
    [SaturnInstance.BY]: import.meta.env.VITE_API_ORIGIN_BY,
    [SaturnInstance.HE]: import.meta.env.VITE_API_ORIGIN_HE,
    [SaturnInstance.DE]: import.meta.env.VITE_API_ORIGIN_DE,
  }[SATURN_INSTANCE] || window.location.origin;

OpenAPIGeocoder.BASE = `${API_ORIGIN}/geocoding`;
OpenAPIRouting.BASE = `${API_ORIGIN}/${
  {
    [SaturnInstance.BW]: 'mobilityapi-stuttgart',
    [SaturnInstance.BY]: 'mobilityapi-bayern',
    [SaturnInstance.HE]: 'mobilityapi-hessen',
    [SaturnInstance.DE]: 'mobilityapi-saturn',
  }[SATURN_INSTANCE]
}`;
