import { Box, css, styled } from '@mui/material';
import { createFileRoute } from '@tanstack/react-router';

import { Dialog } from '@/components/Dialog';
import { MobileNavigation } from '@/components/MobileNavigation';
import { RoutingMap } from '@/components/map/RoutingMap';
import { RoutingMobile } from '@/components/routing/RoutingMobile';
import { RoutingSidebar } from '@/components/routing/RoutingSidebar';
import { useTitle } from '@/hooks/useTitle';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;

    flex-direction: column;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
      display: flex;
    }
  `,
);

function IndexRoute() {
  useTitle();

  return (
    <StyledBox>
      <MobileNavigation />
      <RoutingMobile />
      <RoutingSidebar />

      <RoutingMap />

      <Dialog />
    </StyledBox>
  );
}

export const Route = createFileRoute('/')({
  component: IndexRoute,
});
