import { useEffect } from 'react';

import { IS_SATURN_INSTANCE_HE } from '@/modules/SaturnInstance';
import { SATURN_INSTANCE_LABEL } from '@/modules/SaturnInstanceLabel';

export const useTitle = (title?: string) => {
  useEffect(() => {
    let pageName;
    if (IS_SATURN_INSTANCE_HE) {
      pageName = 'Vielmobil';
    } else {
      pageName = `SATURN ${SATURN_INSTANCE_LABEL}`;
    }

    document.title = title ? `${title} – ${pageName}` : pageName;
  }, [title]);
};
