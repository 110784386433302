import { ExpressionSpecification } from 'maplibre-gl';
import { Layer, Source } from 'react-map-gl/maplibre';

import { ROUTE_BORDER } from '@/components/map/RouteLayers';
import { GeoServer } from '@/modules/GeoServer';
import { LEVEL_OF_SERVICE_COLORS } from '@/modules/LevelOfServiceColors';
import { IS_SATURN_INSTANCE_HE, SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { MARTIN_URL } from '@/setup/martin';
import { MapLayer } from '@/types/MapLayer';

export function LevelOfServiceLayer() {
  return (
    <Source
      id={MapLayer.LEVEL_OF_SERVICE}
      type="vector"
      {...(IS_SATURN_INSTANCE_HE
        ? {
            tiles: [GeoServer.wmts({ layer: 'vis_live_filtered' })],
            bounds: [7.726052171733736, 49.391684399151906, 10.219795599662884, 51.65640759482277],
          }
        : { url: `${MARTIN_URL}/fcd_${SATURN_INSTANCE.toLowerCase()}` })}
    >
      <Layer
        key={MapLayer.LEVEL_OF_SERVICE}
        id={MapLayer.LEVEL_OF_SERVICE}
        type="line"
        source-layer={IS_SATURN_INSTANCE_HE ? 'vis_live_filtered' : `fcd_${SATURN_INSTANCE.toLowerCase()}`}
        beforeId={ROUTE_BORDER}
        // @ts-ignore
        filter={['==', ['typeof', ['get', IS_SATURN_INSTANCE_HE ? 'los' : 'level_of_service']], 'number']}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-opacity': ['step', ['zoom'], ['case', ['>', ['get', 'functional_road_class'], 3], 0, 1], 12, 1],
          'line-color': [
            'match',
            ['get', IS_SATURN_INSTANCE_HE ? 'los' : 'level_of_service'],
            ...LEVEL_OF_SERVICE_COLORS.map(({ value, color }) => [value, color]).flat(),
            'transparent',
          ] as unknown as ExpressionSpecification,
          'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 2, 14, 4, 18, 8],
          'line-offset': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 0, 14, 3, 18, 6],
        }}
      />
    </Source>
  );
}
