import { ClassNames } from '@emotion/react';
import { css, styled, Typography } from '@mui/material';
import { Popup } from 'react-map-gl/maplibre';

import { PopupContent } from '@/components/PopupContent';
import { PopupDatePeriod } from '@/components/popup/PopupDatePeriod';
import { PopupTitle } from '@/components/popup/PopupTitle';
import { ImageProxy } from '@/modules/ImageProxy';
import { SignFeature } from '@/types/SignFeature';

const Image = styled('img')(
  () => css`
    display: block;
    max-width: 100%;
  `,
);

type SignProps = {
  feature?: SignFeature;
  onClose: () => void;
};

export function SignPopup({ feature, onClose }: SignProps) {
  if (!feature) {
    return null;
  }

  const [longitude, latitude] = feature.geometry.coordinates;

  const image = feature.properties.display_url;
  const description = feature.properties.content?.replace('{}', '').replace('{"', '').replace('"}', '');
  const pictogram = !!feature.properties.pictogram_enum;

  return (
    <ClassNames>
      {({ css: cssClassName }) => (
        <Popup
          key={feature.properties.id}
          longitude={longitude}
          latitude={latitude}
          onClose={onClose}
          offset={18}
          maxWidth="min(480px, 75vw)"
          closeButton={false}
          className={
            pictogram
              ? cssClassName`
                  &.maplibregl-popup-anchor-bottom,
                  &.maplibregl-popup-anchor-bottom-left,
                  &.maplibregl-popup-anchor-bottom-right {
                    .maplibregl-popup-tip {
                       border-top-color: black;
                    }
                  }

                  &.maplibregl-popup-anchor-top,
                  &.maplibregl-popup-anchor-top-left,
                  &.maplibregl-popup-anchor-top-right {
                    .maplibregl-popup-tip {
                      border-bottom-color: black;
                    }
                  }

                  &.maplibregl-popup-anchor-left .maplibregl-popup-tip {
                    border-right-color: black;
                  }

                  &.maplibregl-popup-anchor-right .maplibregl-popup-tip {
                    border-left-color: black;
                  }
                `
              : undefined
          }
        >
          <PopupContent onClose={onClose} pictogram={pictogram}>
            <PopupTitle marginBottom>{feature.properties.id}</PopupTitle>
            <PopupDatePeriod feature={feature} />

            {image && <Image src={ImageProxy.resolve(image)} />}
            {description && <Typography>{description}</Typography>}
          </PopupContent>
        </Popup>
      )}
    </ClassNames>
  );
}
