import { useQuery } from '@tanstack/react-query';
import { FeatureCollection } from 'geojson';
import { Layer, Source } from 'react-map-gl/maplibre';

import StrategyIcon from '@/assets/icons/map/strategy.svg?url';
import { useMapImages } from '@/hooks/useMapImages';
import { FeatureCollectionToCenterOfMass } from '@/modules/FeatureCollectionToCenterOfMass';
import { GeoServer } from '@/modules/GeoServer';
import { ICON_SIZE } from '@/modules/IconSize';
import { IS_SATURN_INSTANCE_HE, SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { MARTIN_URL } from '@/setup/martin';
import { MapIcon } from '@/types/MapIcon';
import { MapLayer } from '@/types/MapLayer';

export function StrategyLayers() {
  useMapImages({
    images: [{ name: MapIcon.STRATEGY, url: StrategyIcon, width: 36, height: 36 }],
  });

  const { data: alternativeRouting } = useQuery({
    queryKey: [MapLayer.STRATEGIES, 'ALTERNATIVE_ROUTING'],
    queryFn: () => FeatureCollectionToCenterOfMass.fetch(GeoServer.wfs({ typeName: 'alternativrouten2' })),
    enabled: IS_SATURN_INSTANCE_HE,
  });
  const { data: parkGuidanceCityAvoidance } = useQuery({
    queryKey: [MapLayer.STRATEGIES, 'PARK_GUIDANCE_CITY_AVOIDANCE'],
    queryFn: () => FeatureCollectionToCenterOfMass.fetch(GeoServer.wfs({ typeName: 'parkleitstrategie2' })),
    enabled: IS_SATURN_INSTANCE_HE,
  });
  const { data: gevas } = useQuery({
    queryKey: [MapLayer.STRATEGIES, 'GEVAS'],
    queryFn: () => FeatureCollectionToCenterOfMass.fetch(GeoServer.wfs({ typeName: 'strategien_gevas_tm_routes' })),
    enabled: IS_SATURN_INSTANCE_HE,
  });

  const featureCollection: FeatureCollection = {
    type: 'FeatureCollection',
    features: [
      ...(alternativeRouting?.features || []),
      ...(parkGuidanceCityAvoidance?.features || []),
      ...(gevas?.features || []),
    ],
  };

  return (
    <Source
      key={MapLayer.STRATEGIES}
      id={MapLayer.STRATEGIES}
      {...(IS_SATURN_INSTANCE_HE
        ? {
            type: 'geojson',
            data: featureCollection,
          }
        : {
            type: 'vector',
            url: `${MARTIN_URL}/strategy_${SATURN_INSTANCE.toLowerCase()}`,
          })}
      promoteId="id"
    >
      <Layer
        key={MapLayer.STRATEGIES}
        id={MapLayer.STRATEGIES}
        {...(!IS_SATURN_INSTANCE_HE && {
          'source-layer': `strategy_${SATURN_INSTANCE.toLowerCase()}`,
        })}
        type="symbol"
        layout={{
          'icon-allow-overlap': true,
          'icon-image': MapIcon.STRATEGY,
          'icon-size': ICON_SIZE,
        }}
      />
    </Source>
  );
}
