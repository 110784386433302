import { MenuItem, MenuList } from '@mui/material';
import { Point } from 'geojson';
import { MapGeoJSONFeature, Popup } from 'react-map-gl/maplibre';

import { FeatureGuard } from '@/modules/FeatureGuard';
import { TrafficReport } from '@/modules/TrafficReport';

type FeatureSelectMenuMarkerProps = {
  features?: MapGeoJSONFeature[];
  onClose: () => void;
  onSelect: (feature: MapGeoJSONFeature) => void;
};

export function FeatureSelectMenuMarker({ features, onClose, onSelect }: FeatureSelectMenuMarkerProps) {
  return features ? (
    <Popup
      longitude={(features[0].geometry as Point).coordinates[0]}
      latitude={(features[0].geometry as Point).coordinates[1]}
      closeOnClick
      closeOnMove
      closeButton={false}
      onClose={onClose}
      maxWidth="50vw"
      offset={16}
    >
      <MenuList>
        {features

          .map((feature) => {
            let displayName;

            if (FeatureGuard.isTrafficReportFeature(feature)) {
              displayName = TrafficReport.title(feature) || 'Verkehrsmeldung';
            } else if (FeatureGuard.isSignFeature(feature)) {
              displayName = 'Wechselverkehrszeichen';
            } else if (FeatureGuard.isParkingFeature(feature)) {
              displayName = feature.properties.name || 'Parken';
            } else if (FeatureGuard.isStrategyFeature(feature)) {
              displayName =
                feature.properties?.name?.replace(`${feature.properties.type}: `, '') ||
                feature.properties?.type ||
                'Strategie';
            }

            const featureWithDisplayName: MapGeoJSONFeature & { displayName?: string } = feature;
            featureWithDisplayName.displayName = displayName;

            return featureWithDisplayName;
          })
          .map((feature, index) => (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${feature.id}${index}`}
              onClick={() => {
                onSelect(feature);
                onClose();
              }}
              sx={{
                color: 'text.secondary',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {feature.displayName || 'Unbekanntes Feature'}
            </MenuItem>
          ))}
      </MenuList>
    </Popup>
  ) : null;
}
