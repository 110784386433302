import { Box, styled, css } from '@mui/material';

import BrIcon from '@/assets/icons/modes/bike-and-ride.svg?react';
import BikeIcon from '@/assets/icons/modes/bike.svg?react';
import CarIcon from '@/assets/icons/modes/car.svg?react';
import PrIcon from '@/assets/icons/modes/park-and-ride.svg?react';
import PtIcon from '@/assets/icons/modes/public-transport.svg?react';
import WalkIcon from '@/assets/icons/modes/walk.svg?react';
import { Mode } from '@/types/Mode';
import { transientOptions } from '@/utils/transient-options';

const StyledBox = styled(
  Box,
  transientOptions,
)<{ $variant?: 'default' | 'circle-border' | 'circle-filled' }>(
  ({ $variant, theme }) => css`
    display: flex;

    ${($variant === 'circle-border' || $variant === 'circle-filled') &&
    css`
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
    `}

    ${$variant === 'circle-border' &&
    css`
      border: 1.5px solid ${theme.palette.common.white};
    `}

    ${$variant === 'circle-filled' &&
    css`
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.common.white};
    `}
  `,
);
type ModeIconProps = { mode: Mode | undefined; variant?: 'default' | 'circle-border' | 'circle-filled' };

export function ModeIcon({ mode, variant }: ModeIconProps) {
  let icon;

  switch (mode) {
    case Mode.SELF_DRIVE_CAR:
      icon = <CarIcon />;
      break;
    case Mode.WALK:
      icon = <WalkIcon />;
      break;
    case Mode.CYCLE:
      icon = <BikeIcon />;
      break;
    case Mode.PUBLIC_TRANSPORT:
      icon = <PtIcon />;
      break;
    case Mode.PARK_AND_RIDE:
      icon = <PrIcon />;
      break;
    case Mode.BIKE_AND_RIDE:
      icon = <BrIcon />;
      break;
    default:
      return null;
  }

  return <StyledBox $variant={variant}>{icon}</StyledBox>;
}
