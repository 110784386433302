import { Alert, Box, css, Grid, Grow, LinearProgress, Link, styled, Typography } from '@mui/material';

import { ModeButton } from '@/components/routing/ModeButton';
import { useRoutes } from '@/hooks/useRoutes';
import { IS_SATURN_INSTANCE_HE, SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { TriasHelper } from '@/modules/TriasHelper';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { Mode } from '@/types/Mode';
import { RouteLocationType } from '@/types/RouteLocationType';
import { SaturnInstance } from '@/types/SaturnInstance';
import { Trias } from '@/types/Trias';

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(3)};

    ${theme.breakpoints.up('md')} {
      margin-bottom: ${theme.spacing(2)};
    }
  `,
);

const StyledTypographyLoading = styled(Typography)(
  ({ theme }) => css`
    text-align: center;
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(1)};

    ${theme.breakpoints.up('md')} {
      margin-top: ${theme.spacing(2)};
      margin-bottom: ${theme.spacing(2)};
    }
  `,
);

const StyledLinearProgress = styled(LinearProgress)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(3)};

    ${theme.breakpoints.up('md')} {
      margin-bottom: 0;
    }
  `,
);

const StyledTypographyInfo = styled(StyledTypographyLoading)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(3)};
    max-width: ${theme.spacing(60)};
    margin-left: auto;
    margin-right: auto;
  `,
);

export function RoutingModeSelect() {
  const origin = useRoutesStore((state) => state.routeLocations[RouteLocationType.ORIGIN]);
  const destination = useRoutesStore((state) => state.routeLocations[RouteLocationType.DESTINATION]);
  const selectedMode = useRoutesStore((state) => state.selectedMode);

  const routes = useRoutes();
  const someRouteIsFetching = Object.values(routes).reduce(
    (previousValue, currentValue) => previousValue || !!currentValue?.isFetching,
    false,
  );

  const errors = TriasHelper.getErrors(
    selectedMode.mode &&
      (routes[selectedMode.mode]?.data || ((routes[selectedMode.mode]?.error as any)?.body as unknown as Trias)),
  );

  return (
    <>
      <StyledGrid container spacing={1} justifyContent="center">
        {(SATURN_INSTANCE === SaturnInstance.DE
          ? [Mode.SELF_DRIVE_CAR]
          : [Mode.CYCLE, Mode.WALK, Mode.PUBLIC_TRANSPORT, Mode.SELF_DRIVE_CAR, Mode.BIKE_AND_RIDE, Mode.PARK_AND_RIDE]
        ).map((mode) => (
          <ModeButton key={mode} mode={mode} />
        ))}
      </StyledGrid>

      <Grow
        in={
          someRouteIsFetching ||
          (!someRouteIsFetching && (!selectedMode.mode || selectedMode.mode === Mode.SELF_DRIVE_CAR || !!errors))
        }
        mountOnEnter
        unmountOnExit
      >
        <Box>
          {!someRouteIsFetching &&
            ((origin && destination && !selectedMode.mode) || selectedMode.mode === Mode.SELF_DRIVE_CAR) && (
              <StyledTypographyInfo>
                In der Regel müssen zusätzlich zwischen 10&nbsp;und&nbsp;15&nbsp;Minuten Zu-&nbsp;und&nbsp;Abgangszeit
                bei der PKW-Route eingerechnet werden.
              </StyledTypographyInfo>
            )}

          {errors?.map((error) => {
            let displayText;

            if (
              error.text?.text ===
              'Die errechnete Reisedistanz für das Fahrrad ist länger als das eingestellte Maximum. Bitte versuchen Sie es mit neuen Eingaben noch einmal!'
            ) {
              if (IS_SATURN_INSTANCE_HE && selectedMode.mode === Mode.CYCLE) {
                displayText = (
                  <>
                    Die Route ist für diesen Modus zu lang. Bitte versuchen Sie es mit neuen Eingaben noch einmal oder
                    nutzen Sie den{' '}
                    <Link
                      sx={{ color: 'white', textDecorationColor: 'white' }}
                      href={`https://radroutenplaner.hessen.de/map/?sharelink=1&prm=1&spt=${origin?.geometry.coordinates[0]},${origin?.geometry.coordinates[1]}&ept=${destination?.geometry.coordinates[0]},${destination?.geometry.coordinates[1]}`}
                      target="_blank"
                      referrerPolicy="no-referrer"
                    >
                      Radroutenplaner Hessen
                    </Link>{' '}
                    für längere Routen.
                  </>
                );
              } else {
                displayText =
                  'Die Route ist für diesen Modus zu lang. Bitte versuchen Sie es mit neuen Eingaben noch einmal!';
              }
            }

            return (
              <Alert severity="warning" key={error.code} sx={{ mb: 2 }}>
                {displayText || error.text?.text?.split(' HAFAS-Text')[0]}
              </Alert>
            );
          })}

          {selectedMode.mode && routes[selectedMode.mode]?.isError && (!errors || errors.length === 0) && (
            <Alert severity="warning">
              {(routes[selectedMode.mode]?.error as any)?.status} {(routes[selectedMode.mode]?.error as any)?.message}
            </Alert>
          )}

          {someRouteIsFetching && (
            <>
              <StyledTypographyLoading>
                Verkehrslage, Verkehrsmeldungen und aktive Verkehrslenkungsmaßnahmen auf Ihrer Route werden ermittelt.
              </StyledTypographyLoading>
              <StyledLinearProgress variant="indeterminate" />
            </>
          )}
        </Box>
      </Grow>
    </>
  );
}
