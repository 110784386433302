import { LineString, MultiPolygon } from 'geojson';

import { RouteLineType } from '@/types/RouteLineType';
import { RouteSegmentsFeatureCollection } from '@/types/RouteSegmentFeature';
import { StrategyFeature } from '@/types/StrategyFeature';

export class Strategy {
  public static getGeometries(feature: StrategyFeature, adminMode: boolean) {
    const routeAvoidString = feature.properties.avoided_route || feature.properties.route_avoid_matched;
    const routeAvoid: LineString | undefined = routeAvoidString && JSON.parse(routeAvoidString);

    const routePreferredString = feature.properties.strategy_route || feature.properties.route_matched;
    const routePreferred: LineString | undefined = routePreferredString && JSON.parse(routePreferredString);

    const routeSegments: RouteSegmentsFeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    if (routeAvoid) {
      routeSegments.features.push({
        type: 'Feature',
        geometry: routeAvoid,
        properties: {
          routeLineType: RouteLineType.STRATEGY_AVOID,
        },
      });
    }

    if (routePreferred) {
      routeSegments.features.push({
        type: 'Feature',
        geometry: routePreferred,
        properties: {
          routeLineType: RouteLineType.STRATEGY_PREFERRED,
        },
      });
    }

    const avoidedAreaString =
      feature.properties.avoided_area ||
      (feature.properties.type === 'Stadtverträgliches Routing' && feature.properties.originalGeometry);
    const avoidedArea: MultiPolygon | undefined = adminMode
      ? avoidedAreaString && JSON.parse(avoidedAreaString)
      : undefined;
    const originAreaString =
      feature.properties.origin_area ||
      (feature.properties.type === 'Alternativrouting-Strategie' && feature.properties.originalGeometry);
    const originArea: MultiPolygon | undefined = adminMode
      ? originAreaString && JSON.parse(originAreaString)
      : undefined;
    const destinationAreaString = feature.properties.destination_area || feature.properties.destination;
    const destinationArea: MultiPolygon | undefined = adminMode
      ? destinationAreaString && JSON.parse(destinationAreaString)
      : undefined;

    return {
      routeAvoid,
      routePreferred,
      avoidedArea,
      originArea,
      destinationArea,
    };
  }
}
