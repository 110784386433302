import { Box, Stack, Typography, styled, css } from '@mui/material';

import { LEVEL_OF_SERVICE_COLORS } from '@/modules/LevelOfServiceColors';
import { useMapStore } from '@/stores/useMapStore';

const StyledStack = styled(Stack)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    position: absolute;
    right: ${theme.spacing(5)};
    bottom: ${theme.spacing(1)};
    flex-direction: row;
    background-color: ${theme.palette.common.white};
    padding: ${theme.spacing(1)};

    @media (width > 640px) {
      right: ${theme.spacing(1)};
      bottom: ${theme.spacing(3.5)};
    }

    ${theme.breakpoints.up('md')} {
      top: ${theme.spacing(1)};
      bottom: auto;
    }
  `,
);

const StyledBox = styled(Box)(
  ({ theme }) => css`
    width: ${theme.spacing(4)};
    height: ${theme.spacing(1)};
    opacity: 0.75;
  `,
);

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
    font-size: 0.8rem;
  `,
);

export function LevelOfServiceLegend() {
  const { trafficReport } = useMapStore((state) => state.layersControl);

  return trafficReport ? (
    <StyledStack>
      <StyledTypography mr={1}>langsam</StyledTypography>
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        {[...LEVEL_OF_SERVICE_COLORS].map(({ color }) => (
          <StyledBox key={color} sx={{ backgroundColor: color }} />
        ))}
      </Box>

      <StyledTypography ml={1}>schnell</StyledTypography>
    </StyledStack>
  ) : null;
}
