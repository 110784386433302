import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { UserState } from '@/types/UserState';

export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      dialogVisible: true,

      adminMode: false,

      actions: {
        setDialogVisible: (dialogVisible) => set({ dialogVisible }),
        setAdminMode: (adminMode) => set({ adminMode }),
      },
    }),
    {
      name: 'SATURN/USER',
      version: 1,
      partialize: ({ actions, ...rest }) => rest,
    },
  ),
);
