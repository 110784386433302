import { MapGeoJSONFeature } from 'react-map-gl/maplibre';

import { MapLayer } from '@/types/MapLayer';
import { ParkingFeature } from '@/types/ParkingFeature';
import { SignFeature } from '@/types/SignFeature';
import { StrategyFeature } from '@/types/StrategyFeature';
import { TrafficReportFeature } from '@/types/TrafficReportFeature';

export class FeatureGuard {
  public static isParkingFeature(feature: MapGeoJSONFeature): feature is ParkingFeature & MapGeoJSONFeature {
    return MapLayer.PARKING === feature.layer.id;
  }

  public static isSignFeature(feature: MapGeoJSONFeature): feature is SignFeature & MapGeoJSONFeature {
    return MapLayer.SIGNS === feature.layer.id;
  }

  public static isStrategyFeature(feature: MapGeoJSONFeature): feature is StrategyFeature & MapGeoJSONFeature {
    return MapLayer.STRATEGIES === feature.layer.id;
  }

  public static isTrafficReportFeature(
    feature: MapGeoJSONFeature,
  ): feature is TrafficReportFeature & MapGeoJSONFeature {
    return MapLayer.TRAFFIC_REPORT === feature.layer.id;
  }
}
