import { SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { SaturnInstance } from '@/types/SaturnInstance';

export const SATURN_INSTANCE_LABEL =
  SATURN_INSTANCE === SaturnInstance.HE
    ? undefined
    : {
        [SaturnInstance.BW]: 'Baden-Württemberg',
        [SaturnInstance.BY]: 'Bayern',
        [SaturnInstance.DE]: 'Länderübergreifend',
      }[SATURN_INSTANCE];
