import { Typography, useTheme } from '@mui/material';
import { Layer, Source } from 'react-map-gl/maplibre';

import DestinationPattern from '@/assets/icons/map/destination-pattern.svg?url';
import { PolygonBorderLayer } from '@/components/map/PolygonBorderLayer';
import { MapDetailCard } from '@/components/map-detail-card/MapDetailCard';
import { MapDetailIdentifier } from '@/components/popup/MapDetailIdentifier';
import { PopupDatePeriod } from '@/components/popup/PopupDatePeriod';
import { PopupTitle } from '@/components/popup/PopupTitle';
import { useMapImages } from '@/hooks/useMapImages';
import { BEFORE_LABEL_ID } from '@/modules/BeforeLabelsId';
import { IS_SATURN_INSTANCE_HE, SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { Strategy } from '@/modules/Strategy';
import { useUserStore } from '@/stores/useUserStore';
import { MapIcon } from '@/types/MapIcon';
import { MapLayer } from '@/types/MapLayer';
import { RouteLineType } from '@/types/RouteLineType';
import { RouteSegmentsFeatureCollection } from '@/types/RouteSegmentFeature';
import { StrategyFeature } from '@/types/StrategyFeature';

export const STRATEGIES_HIGHLIGHT_CIRCLE_LAYER = `${MapLayer.STRATEGIES}_HIGHLIGHT_CIRCLE_LAYER`;
export const STRATEGIES_HIGHLIGHT_ICON_LAYER = `${MapLayer.STRATEGIES}_HIGHLIGHT_ICON_LAYER`;

type StrategyPopupProps = {
  feature?: StrategyFeature;
  onClose: () => void;
};

export function StrategyDetailCard({ feature, onClose }: StrategyPopupProps) {
  const theme = useTheme();
  const adminMode = useUserStore((state) => state.adminMode);

  useMapImages({
    images: [{ name: MapIcon.DESTINATION_PATTERN, url: DestinationPattern, width: 32, height: 32 }],
  });

  if (!feature) {
    return null;
  }

  const comments: string[] = JSON.parse(
    (IS_SATURN_INSTANCE_HE
      ? feature.properties.comments?.replace('{}', '[]').replace('{"', '["').replace('"}', '"]')
      : feature.properties.comments) || '[]',
  );
  const geometries = Strategy.getGeometries(feature, adminMode);

  const { routeAvoid, routePreferred, avoidedArea, originArea, destinationArea } = geometries;

  const routeSegments: RouteSegmentsFeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };

  if (routeAvoid) {
    routeSegments.features.push({
      type: 'Feature',
      geometry: routeAvoid,
      properties: {
        routeLineType: RouteLineType.STRATEGY_AVOID,
      },
    });
  }

  if (routePreferred) {
    routeSegments.features.push({
      type: 'Feature',
      geometry: routePreferred,
      properties: {
        routeLineType: RouteLineType.STRATEGY_PREFERRED,
      },
    });
  }

  return (
    <>
      <MapDetailCard onClose={onClose}>
        <PopupTitle>
          {feature.properties.type}

          {feature.properties.name && (
            <>
              <br />
              {feature.properties.name.replace(`${feature.properties.type}: `, '')}
            </>
          )}
        </PopupTitle>
        <MapDetailIdentifier feature={feature} />

        <PopupDatePeriod feature={feature} />

        {comments.map((comment) => (
          <Typography key={comment}>{comment}</Typography>
        ))}
      </MapDetailCard>

      <Layer
        key={STRATEGIES_HIGHLIGHT_CIRCLE_LAYER}
        id={STRATEGIES_HIGHLIGHT_CIRCLE_LAYER}
        filter={['==', 'id', feature.id!]}
        source={MapLayer.STRATEGIES}
        {...(!IS_SATURN_INSTANCE_HE && {
          'source-layer': `strategy_${SATURN_INSTANCE.toLowerCase()}`,
        })}
        type="circle"
        paint={{
          'circle-color': 'white',
          'circle-radius': 24,
        }}
      />
      <Layer
        key={STRATEGIES_HIGHLIGHT_ICON_LAYER}
        id={STRATEGIES_HIGHLIGHT_ICON_LAYER}
        filter={['==', 'id', feature.id as string]}
        source={MapLayer.STRATEGIES}
        {...(!IS_SATURN_INSTANCE_HE && {
          'source-layer': `strategy_${SATURN_INSTANCE.toLowerCase()}`,
        })}
        type="symbol"
        layout={{
          'icon-allow-overlap': true,
          'icon-image': MapIcon.STRATEGY,
        }}
      />

      <PolygonBorderLayer geometry={avoidedArea} color={theme.palette.error.main} />
      <PolygonBorderLayer geometry={originArea} color={theme.palette.info.main} />
      <PolygonBorderLayer
        geometry={destinationArea}
        color={theme.palette.error.main}
        pattern={MapIcon.DESTINATION_PATTERN}
      />

      <Source type="geojson" data={routeSegments}>
        <Layer
          beforeId={BEFORE_LABEL_ID}
          type="line"
          layout={{
            'line-cap': 'round',
          }}
          paint={{
            'line-color': 'white',
            'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 10, 18, 8],
          }}
        />
        <Layer
          beforeId={BEFORE_LABEL_ID}
          type="line"
          layout={{
            'line-cap': 'round',
          }}
          paint={{
            'line-color': [
              'case',
              ['==', ['get', 'routeLineType'], RouteLineType.STRATEGY_AVOID],
              theme.palette.error.main,
              theme.palette.success.main,
            ],
            'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 6, 18, 4],
          }}
        />
      </Source>
    </>
  );
}
