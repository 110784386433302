import centerOfMass from '@turf/center-of-mass';
import { Feature, FeatureCollection, Geometry } from 'geojson';
// @ts-ignore

export class FeatureCollectionToCenterOfMass {
  public static transform(
    featureCollection: FeatureCollection<Geometry, any>,
  ): FeatureCollection<Geometry, { originalGeometry?: Geometry }> {
    return {
      type: 'FeatureCollection',
      features: featureCollection.features.map((feature): Feature<Geometry, { originalGeometry?: Geometry }> => {
        if (feature.geometry.type !== 'Point') {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              originalGeometry: feature.geometry,
            },
            geometry: centerOfMass(feature.geometry).geometry,
          };
        }

        return feature;
      }),
    };
  }

  public static async fetch(url: string) {
    const data: FeatureCollection = await (await fetch(url)).json();

    return FeatureCollectionToCenterOfMass.transform(data);
  }
}
