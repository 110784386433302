import { Box, css, Paper, styled } from '@mui/material';
import { ReactNode } from 'react';

import { PopupContent } from '@/components/PopupContent';

const Root = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    right: ${theme.spacing(1)};
    bottom: ${theme.spacing(1)};
    left: ${theme.spacing(1)};
    display: flex;
    justify-content: center;
  `,
);

const Card = styled(Paper)(
  ({ theme }) => css`
    flex: 1;
    max-height: ${theme.spacing(30)};
    overflow-y: auto;

    ${theme.breakpoints.up('lg')} {
      max-width: 50vw;
    }

    ${theme.breakpoints.up('xl')} {
      max-width: 40vw;
    }
  `,
);

type DetailCardProps = { children: ReactNode; onClose: () => void };

export function MapDetailCard({ children, onClose }: DetailCardProps) {
  return (
    <Root>
      <Card elevation={1}>
        <PopupContent onClose={onClose}>{children}</PopupContent>
      </Card>
    </Root>
  );
}
