import { MapStyleImageMissingEvent } from 'maplibre-gl';
import { useEffect } from 'react';
import { useMap } from 'react-map-gl/maplibre';

import { ImageProxy } from '@/modules/ImageProxy';

export const useMissingMapImages = () => {
  const { current: map } = useMap();

  useEffect(() => {
    if (!map) return;

    map.on('styleimagemissing', async (event: MapStyleImageMissingEvent) => {
      const imageName = event.id;
      const url = ImageProxy.resolve(event.id);

      const imageResponse = await map.loadImage(url);

      if (!map.hasImage(imageName)) {
        map.addImage(imageName, imageResponse.data, { pixelRatio: window.devicePixelRatio });
      }
    });
  }, [map]);
};
