import { Button, Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { AsyncZippable, strToU8, zip } from 'fflate';

import { useRoutes } from '@/hooks/useRoutes';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { useUserStore } from '@/stores/useUserStore';
import { Mode } from '@/types/Mode';
import { RouteLocationType } from '@/types/RouteLocationType';

export function AdminModeControl() {
  const origin = useRoutesStore((state) => state.routeLocations[RouteLocationType.ORIGIN]);
  const destination = useRoutesStore((state) => state.routeLocations[RouteLocationType.DESTINATION]);
  const time = useRoutesStore((state) => state.time);

  const { setAdminMode } = useUserStore((state) => state.actions);

  const routes = useRoutes();

  return (
    <FormGroup sx={{ marginTop: 2 }}>
      <FormLabel sx={{ color: 'common.white', marginBottom: 1, fontWeight: 'bold' }}>Adminmodus</FormLabel>

      <FormControlLabel
        label="Aktiv"
        control={<Checkbox checked onChange={() => setAdminMode(false)} size="small" />}
        sx={{ marginBottom: 1 }}
      />

      <Button
        sx={{
          ':disabled': { color: 'common.white', opacity: 0.5 },
        }}
        disabled={!Object.values(routes).some((result) => !!result?.data)}
        onClick={async () => {
          try {
            const files: AsyncZippable = Object.values(Mode).reduce((previousValue, mode) => {
              const data = routes[mode]?.data;
              if (data) {
                const { triasRequest: request, ...response } = data;

                return {
                  ...previousValue,

                  [mode]: {
                    'request.json': strToU8(JSON.stringify(request, undefined, 2)),
                    'response.json': strToU8(JSON.stringify(response, undefined, 2)),
                  },
                };
              }

              return previousValue;
            }, {});

            const zipFile = await new Promise<Uint8Array>((resolve, reject) => {
              zip(files as AsyncZippable, {}, (error, data) => {
                if (error) {
                  reject(error);
                }

                resolve(data);
              });
            });

            const a = document.createElement('a');
            const file = new Blob([zipFile], { type: 'application/zip' });
            a.href = URL.createObjectURL(file);
            a.download = `${window.location.hostname} - ${origin?.properties.name.full} - ${
              destination?.properties.name.full
            } - ${time.date.format('YYYY-MM-DD HHmm')}.zip`;
            a.click();
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Something went wrong during routes response download');
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }}
      >
        Download Routing
        <br />
        Requests & Responses
      </Button>
    </FormGroup>
  );
}
