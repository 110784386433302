import { LevelOfServiceLayer } from '@/components/map/LevelOfServiceLayer';
import { TrafficReportLayer } from '@/components/map/TrafficReportLayer';

export function TrafficReportLayers() {
  return (
    <>
      <LevelOfServiceLayer />
      <TrafficReportLayer />
    </>
  );
}
