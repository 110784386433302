import { useTheme } from '@mui/material';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { useEffect } from 'react';

import { routeTree } from '@/routeTree.gen';

export const router = createRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export function App() {
  const theme = useTheme();
  const {
    palette: {
      primary: { main: primaryMain },
    },
  } = theme;

  useEffect(() => {
    if (primaryMain && !document.querySelector('meta[name~="theme-color"]')) {
      const meta = document.createElement('meta');
      meta.name = 'theme-color';
      meta.content = primaryMain;
      document.head.appendChild(meta);
    }
  }, [primaryMain]);

  return <RouterProvider router={router} />;
}
