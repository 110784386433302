import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { MapState } from '@/types/MapState';
import { SaturnInstance } from '@/types/SaturnInstance';

export const useMapStore = create<MapState>()(
  persist(
    (set) => ({
      viewState: {
        zoom: 9,
        ...{
          [SaturnInstance.BW]: { longitude: 9.183333, latitude: 48.783333 },
          [SaturnInstance.BY]: { longitude: 11.541950713163182, latitude: 47.97183888408119 },
          [SaturnInstance.HE]: { longitude: 8.676973, latitude: 50.11756 },
          [SaturnInstance.DE]: { longitude: 10.105990919109978, latitude: 49.31228010024064, zoom: 7 },
        }[SATURN_INSTANCE],
      },

      layersControl: {
        strategies: false,
        trafficReport: false,
        signs: false,
        parking: false,
      },

      actions: {
        setViewState: (viewState) => set({ viewState }),

        updateLayersControl: (newLayersControl) =>
          set(({ layersControl }) => ({
            layersControl: {
              ...layersControl,
              ...newLayersControl,
            },
          })),
      },
    }),
    {
      name: 'SATURN/MAP',
      version: 1,
      partialize: ({ actions, viewState, ...rest }) => rest,
    },
  ),
);
