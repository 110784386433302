export class ImageProxy {
  public static resolve(resolve: string) {
    const url = new URL(resolve);

    if (url.origin === 'http://sdbby.heuboe.de:11447') {
      return new URL(`/proxy/image-by${url.pathname}`, import.meta.env.VITE_API_ORIGIN_DE).toString();
    }

    return resolve;
  }
}
