import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from 'react-dom/client';

import '@/setup/sentry';
import '@/setup/api';
import '@/setup/dayjs';
import { App } from '@/App';
import trafficonFavicon from '@/assets/favicons/trafficon.svg?url';
import vielmobilFavicon from '@/assets/favicons/vielmobil.svg?url';
import { IS_SATURN_INSTANCE_HE } from '@/modules/SaturnInstance';
import { queryClient } from '@/setup/query-client';
import { theme } from '@/setup/theme';

const link = document.createElement('link');
link.rel = 'icon';
link.type = 'image/svg+xml';
link.href = IS_SATURN_INSTANCE_HE ? vielmobilFavicon : trafficonFavicon;
document.head.appendChild(link);

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
