import { useMediaQuery, useTheme } from '@mui/material';
import bbox from '@turf/bbox';
import { GeometryCollection, LineString, MultiPolygon } from 'geojson';
import { useRef, useState } from 'react';
import Map, { AttributionControl, MapGeoJSONFeature, MapRef } from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';

import BasemapDeStyle from '@/assets/basemap-de-style.json?url';
import { BorderShape } from '@/components/map/BorderShape';
import { ContextMenuMarker } from '@/components/map/ContextMenuMarker';
import { FeatureSelectMenuMarker } from '@/components/map/FeatureSelectMenuMarker';
import { LayerVisible } from '@/components/map/LayerVisible';
import { LayersControl } from '@/components/map/LayersControl';
import { LevelOfServiceLegend } from '@/components/map/LevelOfServiceLegend';
import { ParkingLayer } from '@/components/map/ParkingLayer';
import { RouteLayers } from '@/components/map/RouteLayers';
import { RouteLocationMarkers } from '@/components/map/RouteLocationMarkers';
import { SignLayers } from '@/components/map/SignLayers';
import { StrategyLayers } from '@/components/map/StrategyLayers';
import { TrafficReportLayers } from '@/components/map/TrafficReportLayers';
import { StrategyDetailCard } from '@/components/map-detail-card/StrategyDetailCard';
import { TrafficReportDetailCard } from '@/components/map-detail-card/TrafficReportDetailCard';
import { ParkingPopup } from '@/components/popup/ParkingPopup';
import { SignPopup } from '@/components/popup/SignPopup';
import { FeatureGuard } from '@/modules/FeatureGuard';
import { Strategy } from '@/modules/Strategy';
import { useMapStore } from '@/stores/useMapStore';
import { useUserStore } from '@/stores/useUserStore';
import { MapLayer } from '@/types/MapLayer';
import { ParkingFeature } from '@/types/ParkingFeature';
import { SignFeature } from '@/types/SignFeature';
import { StrategyFeature } from '@/types/StrategyFeature';
import { TrafficReportFeature } from '@/types/TrafficReportFeature';

export function RoutingMap() {
  const map = useRef<MapRef>(null);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const adminMode = useUserStore((state) => state.adminMode);
  const viewState = useMapStore((state) => state.viewState);
  const { setViewState } = useMapStore((state) => state.actions);

  const [contextMenu, setContextMenu] = useState<{ longitude: number; latitude: number }>();

  const [trafficReportFeature, setTrafficReportFeature] = useState<TrafficReportFeature>();
  const [signFeature, setSignFeature] = useState<SignFeature>();
  const [parkingFeature, setParkingFeature] = useState<ParkingFeature>();
  const [featureSelectMenu, setFeatureSelectMenu] = useState<MapGeoJSONFeature[]>();

  const [strategyFeature, setStrategyFeature] = useState<StrategyFeature>();

  const { strategies, signs, trafficReport, parking } = useMapStore((state) => state.layersControl);

  const handleFeatureSelect = (feature: MapGeoJSONFeature) => {
    if (FeatureGuard.isTrafficReportFeature(feature)) {
      setStrategyFeature(undefined);
      setTrafficReportFeature(feature);

      const geometry = feature.properties.geom ? JSON.parse(feature.properties.geom) : undefined;

      if (geometry) {
        map.current?.fitBounds(bbox(geometry) as maplibregl.LngLatBoundsLike, {
          padding: { top: 64, right: 64, bottom: 320, left: 64 },
          maxZoom: 18,
        });
      }
    } else if (FeatureGuard.isSignFeature(feature)) {
      setSignFeature(feature);
    } else if (FeatureGuard.isParkingFeature(feature)) {
      setParkingFeature(feature);
    } else if (FeatureGuard.isStrategyFeature(feature)) {
      setTrafficReportFeature(undefined);
      setStrategyFeature(feature);

      const geometries = Strategy.getGeometries(feature, adminMode);

      const geometryCollection: GeometryCollection = {
        type: 'GeometryCollection',
        geometries: [
          feature.geometry,
          ...Object.values(geometries).filter((geometry): geometry is LineString | MultiPolygon => !!geometry),
        ],
      };

      map.current?.fitBounds(bbox(geometryCollection) as maplibregl.LngLatBoundsLike, {
        padding: { top: 64, right: 64, bottom: 320, left: 64 },
        maxZoom: 18,
      });
    }
  };

  return (
    <Map
      ref={map}
      {...viewState}
      // @ts-ignore
      RTLTextPlugin={null}
      onMove={(event) => setViewState(event.viewState)}
      mapStyle={BasemapDeStyle}
      touchPitch={false}
      pitchWithRotate={false}
      dragRotate={false}
      onContextMenu={(event) => {
        setContextMenu({ longitude: event.lngLat.lng, latitude: event.lngLat.lat });
      }}
      interactiveLayerIds={[
        ...(parking ? [MapLayer.PARKING] : []),
        ...(signs ? [MapLayer.SIGNS] : []),
        ...(strategies ? [MapLayer.STRATEGIES] : []),
        ...(trafficReport ? [MapLayer.TRAFFIC_REPORT] : []),
      ]}
      onClick={(event) => {
        if (event.features && event.features.length > 0) {
          const { features } = event;

          const filteredFeatures = features.filter(
            (feature) => JSON.stringify(feature.geometry) === JSON.stringify(features[0].geometry),
          );

          if (filteredFeatures.length === 1) {
            handleFeatureSelect(filteredFeatures[0]);
          } else if (filteredFeatures.length > 1) {
            setFeatureSelectMenu(filteredFeatures);
          }
        } else {
          setStrategyFeature(undefined);
          setTrafficReportFeature(undefined);
        }
      }}
      attributionControl={false}
    >
      {!strategyFeature && !trafficReportFeature && <AttributionControl position="bottom-right" />}
      {!(mobile && (strategyFeature || trafficReportFeature)) && (
        <>
          <LayersControl />
          <LevelOfServiceLegend />
        </>
      )}

      <BorderShape />

      <RouteLayers />
      <RouteLocationMarkers />

      <LayerVisible layer="parking">
        <ParkingLayer />
      </LayerVisible>
      <LayerVisible layer="trafficReport">
        <TrafficReportLayers />
      </LayerVisible>
      <LayerVisible layer="signs">
        <SignLayers />
      </LayerVisible>
      <LayerVisible layer="strategies">
        <StrategyLayers />
      </LayerVisible>

      <FeatureSelectMenuMarker
        features={featureSelectMenu}
        onClose={() => setFeatureSelectMenu(undefined)}
        onSelect={(feature) => handleFeatureSelect(feature)}
      />
      <ParkingPopup feature={parkingFeature} onClose={() => setParkingFeature(undefined)} />
      <TrafficReportDetailCard feature={trafficReportFeature} onClose={() => setTrafficReportFeature(undefined)} />
      <SignPopup feature={signFeature} onClose={() => setSignFeature(undefined)} />
      {strategyFeature && (
        <StrategyDetailCard feature={strategyFeature} onClose={() => setStrategyFeature(undefined)} />
      )}

      <ContextMenuMarker
        longitude={contextMenu?.longitude}
        latitude={contextMenu?.latitude}
        onClose={() => setContextMenu(undefined)}
      />
    </Map>
  );
}
