import { Box } from '@mui/material';

import { ResultStructureCollapse } from '@/components/result/ResultStructureCollapse';
import { useRoutes } from '@/hooks/useRoutes';
import { TriasHelper } from '@/modules/TriasHelper';
import { useRoutesStore } from '@/stores/useRoutesStore';

type RoutingResultsProp = { showDetails?: () => void };

export function ResultStructuresList({ showDetails }: RoutingResultsProp) {
  const { mode } = useRoutesStore((state) => state.selectedMode);
  const routes = useRoutes();

  if (!mode) {
    return null;
  }

  return (
    <Box mt={2}>
      {TriasHelper.getResultStructures(routes[mode]?.data)?.map((route, index) => (
        <ResultStructureCollapse
          key={route.resultId}
          mode={mode}
          resultStructure={route}
          structureIndex={index}
          handleDetails={showDetails}
        />
      ))}
    </Box>
  );
}
