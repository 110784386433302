import { Box, css, Fade, IconButton, Slide, styled, useMediaQuery, useTheme } from '@mui/material';
import { useMatch } from '@tanstack/react-router';
import { useEffect, useState } from 'react';

import ChevronDownIcon from '@/assets/icons/chevron-down-circle.svg?react';
import ChevronUpIcon from '@/assets/icons/chevron-up-circle.svg?react';
import { ResultStructureDetails } from '@/components/result/ResultStructureDetails';
import { ResultStructuresList } from '@/components/result/ResultStructuresList';
import { RoutingForm } from '@/components/routing/RoutingForm';
import { RoutingModeSelect } from '@/components/routing/RoutingModeSelect';
import { useRoutes } from '@/hooks/useRoutes';
import { TriasHelper } from '@/modules/TriasHelper';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { RouteLocationType } from '@/types/RouteLocationType';
import { RoutingStep } from '@/types/RoutingStep';
import { transientOptions } from '@/utils/transient-options';

const StyledFixedBox = styled(
  Box,
  transientOptions,
)<{ $zIndex: number }>(
  ({ theme, $zIndex }) => css`
    position: fixed;
    top: ${theme.spacing(7)};
    left: 0;
    right: 0;
    z-index: ${$zIndex};

    ${theme.breakpoints.up('sm')} {
      top: ${theme.spacing(8)};
    }
  `,
);

const StyledPreviousButton = styled(
  IconButton,
  transientOptions,
)<{ $open?: boolean }>(
  ({ theme, $open }) => css`
    position: absolute;
    left: 50%;
    transform: translate(-50%, -40%) ${!$open && 'rotate(-180deg)'};
    transition: ${theme.transitions.create('transform')};
    z-index: 101;
    color: ${theme.palette.primary.light};
  `,
);

const StyledNextButton = styled(IconButton)(
  ({ theme }) => css`
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    color: ${theme.palette.primary.light};
  `,
);

const StyledContentBox = styled(Box)(
  ({ theme }) => css`
    max-height: calc(100vh - ${theme.spacing(7)});
    overflow: scroll;
    background-color: ${theme.palette.primary.main};
    padding: ${theme.spacing(2)};

    ${theme.breakpoints.up('sm')} {
      max-height: calc(100vh - ${theme.spacing(8)});
    }
  `,
);

export function RoutingMobile() {
  const [routingStep, setRoutingStep] = useState(RoutingStep.CLOSED);

  const theme = useTheme();
  const show = useMediaQuery(theme.breakpoints.down('md'));

  const match = useMatch({ from: '/' });

  const origin = useRoutesStore((state) => state.routeLocations[RouteLocationType.ORIGIN]);
  const destination = useRoutesStore((state) => state.routeLocations[RouteLocationType.DESTINATION]);
  const selectedMode = useRoutesStore((state) => state.selectedMode);

  const routes = useRoutes();

  const selectedModeIsFetching = selectedMode.mode && routes[selectedMode.mode]?.isFetching;
  useEffect(() => {
    if (routingStep > RoutingStep.MODE_SELECT && selectedMode.mode && selectedModeIsFetching) {
      setRoutingStep(RoutingStep.MODE_SELECT);
    }
  }, [routingStep, selectedModeIsFetching, selectedMode.mode]);

  const resultStructure =
    selectedMode.mode &&
    TriasHelper.getResultStructures(routes[selectedMode.mode]?.data)?.[selectedMode.structureIndex];

  if (!show || !match) {
    return null;
  }

  const handleClickPrevious = () => {
    setRoutingStep((prevState) => (prevState === RoutingStep.CLOSED ? prevState + 1 : prevState - 1));
  };
  const handleClickNext = () => {
    setRoutingStep((prevState) => prevState + 1);
  };

  const showDetails = () => {
    setRoutingStep(RoutingStep.RESULT_DETAILS);
  };

  return (
    <>
      <StyledFixedBox $zIndex={101}>
        <StyledPreviousButton onClick={handleClickPrevious} $open={routingStep !== RoutingStep.CLOSED}>
          <ChevronUpIcon />
        </StyledPreviousButton>
      </StyledFixedBox>

      <Slide in={routingStep !== RoutingStep.CLOSED}>
        <StyledFixedBox $zIndex={99}>
          <StyledContentBox>
            {(routingStep === RoutingStep.CLOSED || routingStep === RoutingStep.FORM) && <RoutingForm />}
            {routingStep === RoutingStep.MODE_SELECT && <RoutingModeSelect />}
            {routingStep === RoutingStep.RESULTS && <ResultStructuresList showDetails={showDetails} />}

            {routingStep === RoutingStep.RESULT_DETAILS && resultStructure && (
              <ResultStructureDetails mobile resultStructure={resultStructure} />
            )}
          </StyledContentBox>

          <Fade
            in={
              (routingStep === RoutingStep.FORM && !!origin && !!destination) ||
              (routingStep === RoutingStep.MODE_SELECT && !!selectedMode.mode && !!resultStructure)
            }
          >
            <StyledNextButton onClick={handleClickNext}>
              <ChevronDownIcon />
            </StyledNextButton>
          </Fade>
        </StyledFixedBox>
      </Slide>
    </>
  );
}
