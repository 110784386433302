import { Point } from 'geojson';

import { TriasHelper } from '@/modules/TriasHelper';
import { InterchangeLegStructure } from '@/modules/api-routing';
import { InterchangeFeature, InterchangesFeatureCollection } from '@/types/InterchangeFeature';
import { InterchangeType } from '@/types/InterchangeType';
import { Mode } from '@/types/Mode';
import { UseRoutesResult } from '@/types/UseRoutesResult';

export class TriasInterchanges {
  public static get(routes: UseRoutesResult, selectedMode: { mode: Mode | undefined; structureIndex: number }) {
    const featureCollection: InterchangesFeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    const route =
      selectedMode.mode &&
      TriasHelper.getIntermodalTripStructure(routes[selectedMode.mode]?.data, selectedMode.structureIndex);
    if (route === undefined) return featureCollection;

    route.tripLeg?.forEach((leg) => {
      if (
        leg.interchangeLeg?.interchangeMode === InterchangeLegStructure.interchangeMode.BIKE_AND_RIDE ||
        leg.interchangeLeg?.interchangeMode === InterchangeLegStructure.interchangeMode.PARK_AND_RIDE
      ) {
        const pointInterchange: Point = {
          type: 'Point',
          coordinates: [],
        };

        const legStartPosition = leg.interchangeLeg?.legStart?.geoPosition;
        pointInterchange.coordinates.push(legStartPosition?.longitude as number, legStartPosition?.latitude as number);
        const interchangeName = leg.interchangeLeg?.legEnd?.locationName?.text;

        const feature: InterchangeFeature = {
          type: 'Feature',
          geometry: pointInterchange,
          properties: {
            name: interchangeName,
            interchangeType: {
              [InterchangeLegStructure.interchangeMode.BIKE_AND_RIDE]: InterchangeType.BIKE_AND_RIDE,
              [InterchangeLegStructure.interchangeMode.PARK_AND_RIDE]: InterchangeType.PARK_AND_RIDE,
            }[leg.interchangeLeg.interchangeMode],
          },
        };

        featureCollection.features.push(feature);
      }
    });

    return featureCollection;
  }
}
