import { Box, Typography } from '@mui/material';
import { Feature, Geometry } from 'geojson';

type MapDetailIdentifierProps = {
  feature: Feature<Geometry, { id: number | string; source?: string; matched?: boolean }>;
};

export function MapDetailIdentifier({ feature }: MapDetailIdentifierProps) {
  return (
    <Typography color="text.disabled" fontSize="0.75rem" fontFamily="monospace" mb={1}>
      {feature.properties.id && (
        <>
          ID: {feature.properties.id}
          <br />
        </>
      )}
      {feature.properties.source && (
        <>
          Quelle: {feature.properties.source}
          <br />
        </>
      )}
      {typeof feature.properties.matched === 'boolean' && (
        <>
          Matching:{' '}
          <Box component="span" sx={{ color: feature.properties.matched ? 'success.main' : 'error.main' }}>
            {feature.properties.matched ? 'Erfolgreich' : 'Fehlgeschlagen'}
          </Box>
        </>
      )}
    </Typography>
  );
}
