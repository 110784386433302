import { Box, css, IconButton, styled } from '@mui/material';
import { ReactNode } from 'react';

import CloseIcon from '@/assets/icons/cross.svg?react';
import { transientOptions } from '@/utils/transient-options';

const Root = styled(
  Box,
  transientOptions,
)<{ $pictogram?: boolean }>(
  ({ theme, $pictogram }) => css`
    position: relative;
    padding: ${theme.spacing(4)};
    background-color: ${$pictogram ? theme.palette.common.black : theme.palette.common.white};
    color: ${$pictogram ? theme.palette.text.primary : theme.palette.text.secondary};
  }
  `,
);

const CloseButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(0.5)};
    right: ${theme.spacing(0.5)};
    color: inherit;
  }
  `,
);
type PopupContentProps = { children: ReactNode; onClose?: () => void; pictogram?: boolean };

export function PopupContent({ children, onClose, pictogram }: PopupContentProps) {
  return (
    <Root $pictogram={pictogram}>
      {onClose && (
        <CloseButton aria-label="Schließen" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      )}

      {children}
    </Root>
  );
}
