import { DetailWrapper } from '@/components/result/DetailWrapper';
import { ServiceTimeMeta } from '@/components/result/ServiceTimeMeta';
import { ModeIcon } from '@/components/routing/ModeIcon';
import { TimedLegStructure } from '@/modules/api-routing';
import { Mode } from '@/types/Mode';

type TimedLegDetailProps = {
  leg: TimedLegStructure;
};

export function TimedLegDetail({ leg }: TimedLegDetailProps) {
  return (
    <>
      <DetailWrapper
        bold
        left={<ServiceTimeMeta serviceTime={leg.legBoard?.serviceDeparture} />}
        right={leg.legBoard?.stopPointName?.text}
      />
      <DetailWrapper
        left={<ModeIcon mode={Mode.PUBLIC_TRANSPORT} />}
        right={
          <>
            {leg.service?.publishedLineName?.text}
            <br />
            nach {leg.service?.destinationText?.text}
          </>
        }
      />
      <DetailWrapper
        bold
        left={<ServiceTimeMeta serviceTime={leg.legAlight?.serviceArrival} />}
        right={leg.legAlight?.stopPointName?.text}
      />
    </>
  );
}
