import { useQueries } from '@tanstack/react-query';

import { Routing } from '@/modules/Routing';
import { SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { Mode } from '@/types/Mode';
import { RouteLocationType } from '@/types/RouteLocationType';
import { SaturnInstance } from '@/types/SaturnInstance';
import { UseRoutesResult } from '@/types/UseRoutesResult';

export const useRoutes = (): UseRoutesResult => {
  const origin = useRoutesStore((state) => state.routeLocations[RouteLocationType.ORIGIN]);
  const destination = useRoutesStore((state) => state.routeLocations[RouteLocationType.DESTINATION]);
  const time = useRoutesStore((state) => state.time);

  const results = useQueries({
    queries: (SATURN_INSTANCE === SaturnInstance.DE
      ? [Mode.SELF_DRIVE_CAR]
      : [Mode.CYCLE, Mode.PUBLIC_TRANSPORT, Mode.SELF_DRIVE_CAR, Mode.BIKE_AND_RIDE, Mode.PARK_AND_RIDE]
    ).map((mode) => ({
      queryKey: ['routes', mode, { origin, destination, time }],
      queryFn: () => Routing.get({ origin: origin!, destination: destination!, time, mode }),
      enabled: !!origin && !!destination && time.date.isValid(),
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    })),
  });

  return SATURN_INSTANCE === SaturnInstance.DE
    ? {
        [Mode.CYCLE]: undefined,
        [Mode.WALK]: undefined,
        [Mode.PUBLIC_TRANSPORT]: undefined,
        [Mode.SELF_DRIVE_CAR]: results[0],
        [Mode.BIKE_AND_RIDE]: undefined,
        [Mode.PARK_AND_RIDE]: undefined,
      }
    : {
        [Mode.CYCLE]: results[0],
        [Mode.WALK]: results[0],
        [Mode.PUBLIC_TRANSPORT]: results[1],
        [Mode.SELF_DRIVE_CAR]: results[2],
        [Mode.BIKE_AND_RIDE]: results[3],
        [Mode.PARK_AND_RIDE]: results[4],
      };
};
