/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Trias } from '../models/Trias';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoutingAPI {

    /**
     * Accepts TRIAS IndividualRouteRequest and returns TRIAS IndividualRouteResponse
     * Accepts an IndividualRouteRequest of a TRIAS service.
     * @returns Trias OK. TRIAS TripRequest is processed.
     * @throws ApiError
     */
    public static getIndividualRoutes({
        triasRequest,
    }: {
        /**
         * triasRequest
         */
        triasRequest: Trias,
    }): CancelablePromise<Trias> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/request-individual-route-trias',
            body: triasRequest,
            errors: {
                400: `BAD REQUEST - see more detailed content within the TRIAS error response!`,
                404: `The resource could not be found!!!`,
                500: `Internal Server Error of unknown reason; please see the TRIAS error message`,
            },
        });
    }

    /**
     * Accepts TRIAS TripRequest and returns TRIAS TripResponse
     * Accepts an intermodal TripRequest including TraffiCon extensions.
     * @returns Trias OK. TRIAS TripRequest is processed.
     * @throws ApiError
     */
    public static getIntermodalTrips({
        triasRequest,
    }: {
        /**
         * triasRequest
         */
        triasRequest: Trias,
    }): CancelablePromise<Trias> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/request-intermodal-route-trias',
            body: triasRequest,
            errors: {
                400: `BAD REQUEST - see more detailed content within the TRIAS error response!`,
                404: `The resource could not be found!!!`,
                500: `Internal Server Error of unknown reason; please see the TRIAS error message`,
            },
        });
    }

}
