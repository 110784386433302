import { Box, styled, css, FormGroup, IconButton, Paper, FormControlLabel, Checkbox } from '@mui/material';

import LayersIcon from '@/assets/icons/layers.svg?react';
import { AdminModeControl } from '@/components/map/AdminModeControl';
import { IS_SATURN_INSTANCE_HE } from '@/modules/SaturnInstance';
import { useMapStore } from '@/stores/useMapStore';
import { useUserStore } from '@/stores/useUserStore';
import { MapState } from '@/types/MapState';

const layers: { key: keyof MapState['layersControl']; label: string }[] = [
  { key: 'strategies', label: 'Verkehrslenkung' },
  { key: 'trafficReport', label: 'Verkehr' },
  { key: 'signs', label: 'Wechselverkehrszeichen' },
  ...(!IS_SATURN_INSTANCE_HE ? [{ key: 'parking', label: 'Parken' } as const] : []),
];

const Root = styled(Box)(
  ({ theme }) => css`
    z-index: 1;
    position: absolute;
    bottom: ${theme.spacing(1)};
    left: ${theme.spacing(1)};

    ${theme.breakpoints.up('md')} {
      bottom: auto;
      top: ${theme.spacing(1)};
    }

    &:hover {
      .layers-control-button {
        display: none;
      }

      .layers-control-paper {
        display: block;
      }
    }
  `,
);

const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    display: none;
    padding: ${theme.spacing(2)};
  `,
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.common.white};
  `,
);

export function LayersControl() {
  const layersControl = useMapStore((state) => state.layersControl);
  const { updateLayersControl } = useMapStore((state) => state.actions);
  const adminMode = useUserStore((state) => state.adminMode);

  return (
    <Root>
      <StyledIconButton className="layers-control-button">
        <LayersIcon />
      </StyledIconButton>

      <StyledPaper className="layers-control-paper">
        <FormGroup>
          {layers.map(({ key, label }) => (
            <FormControlLabel
              key={key}
              label={label}
              control={
                <Checkbox
                  checked={layersControl[key]}
                  onChange={(event, checked) =>
                    updateLayersControl({
                      [key]: checked,
                    })
                  }
                  size="small"
                />
              }
            />
          ))}
        </FormGroup>

        {adminMode && <AdminModeControl />}
      </StyledPaper>
    </Root>
  );
}
