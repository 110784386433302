import { SaturnInstance } from '@/types/SaturnInstance';

const getSaturnInstance = () => {
  const { hostname } = window.location;

  if (hostname.includes('vielmobil')) {
    return SaturnInstance.HE;
  }

  return (
    {
      bw: SaturnInstance.BW,
      by: SaturnInstance.BY,
      he: SaturnInstance.HE,
    }[window.location.hostname.split('.')[0]] || SaturnInstance.DE
  );
};

export const SATURN_INSTANCE = getSaturnInstance();
export const IS_SATURN_INSTANCE_HE = SATURN_INSTANCE === SaturnInstance.HE;
