import { alpha, css, createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material';
import { deDE } from '@mui/material/locale';

import type {} from '@mui/x-date-pickers/themeAugmentation';

import '@fontsource/raleway/latin-400.css';
import '@fontsource/raleway/latin-700.css';

let themeBuilder = createTheme(
  {
    palette: {
      primary: {
        dark: '#207f99',
        main: '#29a4c5',
        contrastText: '#ffffff',
      },

      text: {
        primary: '#ffffff',
        secondary: '#000000',
      },

      action: {
        active: '#ffffff',
      },

      error: {
        main: '#e20613',
      },
      success: {
        main: '#57ad00',
      },
      info: {
        main: '#3C91E6',
      },
    },
    typography: {
      fontSize: 16,
      fontFamily: ['"Raleway"', 'sans-serif'].join(','),

      allVariants: {
        fontSize: '1rem',
      },
      h1: {
        fontFamily: ['"Raleway"', 'sans-serif'].join(','),
        fontWeight: 'bold',
        fontSize: '1.8rem',
      },
      h2: {
        fontFamily: ['"Raleway"', 'sans-serif'].join(','),
        fontWeight: 'bold',
      },
    },
    shape: {
      borderRadius: 0,
    },
    shadows: [
      'none',
      '0 0.2px 0.4px rgba(0, 0, 0, 0.017), 0 0.5px 1.1px rgba(0, 0, 0, 0.024), 0 1px 2px rgba(0, 0, 0, 0.03), 0 1.8px 3.6px rgba(0, 0, 0, 0.036), 0 3.3px 6.7px rgba(0, 0, 0, 0.043), 0 8px 16px rgba(0, 0, 0, 0.06)',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
  },
  deDE,
);

themeBuilder = createTheme(themeBuilder, {
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: css`
          background-color: ${themeBuilder.palette.primary.main};
        `,
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: css`
          &::before,
          &:hover:not(.Mui-disabled)::before {
            border-bottom-color: ${alpha(themeBuilder.palette.common.white, 0.42)};
          }

          &::after {
            border-bottom-color: ${themeBuilder.palette.common.white};
          }
        `,
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: css`
          color: ${themeBuilder.palette.common.white};
          font-weight: bold;
        `,
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: css`
          background-color: ${themeBuilder.palette.primary.main};

          &:not(.Mui-selected) {
            border-color: ${themeBuilder.palette.primary.dark};
          }
        `,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: css`
          color: ${themeBuilder.palette.common.white};
        `,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: css`
          border-radius: ${themeBuilder.spacing(2.5)};
          border: 2px solid ${themeBuilder.palette.common.white};
          text-transform: none;
          font-weight: bold;
          font-size: 0.8rem;
          color: ${themeBuilder.palette.common.white};
        `,
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: css`
          color: ${themeBuilder.palette.text.secondary};
          border-radius: ${themeBuilder.spacing(2.5)};
        `,
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: css`
          color: ${themeBuilder.palette.common.white};

          &.Mui-checked {
            color: ${themeBuilder.palette.common.white};
          }
        `,
      },
    },
    MuiChip: {
      styleOverrides: {
        colorDefault: {
          color: themeBuilder.palette.text.secondary,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: css`
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        `,

        '#root': css`
          height: 100%;
        `,

        '.maplibregl-popup-content': css`
          padding: 0;
          background-color: ${themeBuilder.palette.common.white};
          box-shadow: ${themeBuilder.shadows[1]};
        `,

        '.maplibregl-ctrl-attrib': css`
          color: ${themeBuilder.palette.common.black};

          &.maplibregl-compact {
            @media (width <= 640px) {
              margin: ${themeBuilder.spacing(1)} ${themeBuilder.spacing(1)};
            }
          }
        `,
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
} as ThemeOptions);

themeBuilder = responsiveFontSizes(themeBuilder);

export const theme = themeBuilder;
