import { RouteResultStructure, TripResultStructure } from '@/modules/api-routing';
import { Mode } from '@/types/Mode';

export class ModeGuard {
  public static isIndividualMode(mode: Mode): mode is Mode.WALK | Mode.CYCLE | Mode.SELF_DRIVE_CAR {
    return mode === Mode.WALK || mode === Mode.CYCLE || mode === Mode.SELF_DRIVE_CAR;
  }

  public static isIndividualResult(
    mode: Mode,
    route: RouteResultStructure | TripResultStructure,
  ): route is RouteResultStructure {
    return ModeGuard.isIndividualMode(mode);
  }

  public static isIntermodalMode(mode: Mode): mode is Mode.PUBLIC_TRANSPORT | Mode.BIKE_AND_RIDE | Mode.PARK_AND_RIDE {
    return mode === Mode.PUBLIC_TRANSPORT || mode === Mode.BIKE_AND_RIDE || mode === Mode.PARK_AND_RIDE;
  }

  public static isIntermodalResult(
    mode: Mode,
    route: RouteResultStructure | TripResultStructure,
  ): route is TripResultStructure {
    return ModeGuard.isIntermodalMode(mode);
  }
}
