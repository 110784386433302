import { Chip, Stack, Typography } from '@mui/material';
import { Popup } from 'react-map-gl/maplibre';

import { PopupContent } from '@/components/PopupContent';
import { PopupTitle } from '@/components/popup/PopupTitle';
import { ParkingFeature } from '@/types/ParkingFeature';

type ParkingPopupProps = {
  feature?: ParkingFeature;
  onClose: () => void;
};

export function ParkingPopup({ feature, onClose }: ParkingPopupProps) {
  if (!feature) {
    return null;
  }

  const [longitude, latitude] = feature.geometry.coordinates;

  const { name } = feature.properties;

  return (
    <Popup
      key={feature.properties.parking_facility_id}
      longitude={longitude}
      latitude={latitude}
      onClose={onClose}
      offset={18}
      maxWidth="min(480px, 75vw)"
      closeButton={false}
    >
      <PopupContent onClose={onClose}>
        {name && <PopupTitle marginBottom>{name}</PopupTitle>}

        {typeof feature.properties.short_term_parking_free === 'number' && (
          <Typography mb={3} fontSize="1.25rem">
            <strong>{feature.properties.short_term_parking_free}</strong>
            {(typeof feature.properties.num_parking_space_short_term === 'number' ||
              typeof feature.properties.num_parking_space_total === 'number') && (
              <>
                {' '}
                von{' '}
                <strong>
                  {typeof feature.properties.num_parking_space_short_term === 'number'
                    ? feature.properties.num_parking_space_short_term
                    : feature.properties.num_parking_space_total}
                </strong>
              </>
            )}{' '}
            verfügbar
          </Typography>
        )}

        <Typography variant="h2" mb={1}>
          Parkplatzarten
        </Typography>
        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
          {(
            [
              { key: 'num_parking_space_disabled', label: 'Behinderte' },
              { key: 'num_parking_space_e_vehicle', label: 'E-Ladeplätze' },
              { key: 'num_parking_space_family', label: 'Familien' },
              { key: 'num_parking_space_long_term', label: 'Langzeit' },
              { key: 'num_parking_space_short_term', label: 'Kurzzeit' },
              { key: 'num_parking_space_women', label: 'Frauen' },
              { key: 'num_parking_space_total', label: 'Total' },
            ] as const
          ).map(({ key, label }) => {
            const value = feature.properties[key];

            return value ? (
              <Chip
                color={key !== 'num_parking_space_total' ? 'primary' : 'default'}
                key={key}
                label={
                  <>
                    <strong>{value}</strong> {label}
                  </>
                }
              />
            ) : null;
          })}
        </Stack>
      </PopupContent>
    </Popup>
  );
}
