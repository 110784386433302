import { Box, css, styled } from '@mui/material';

import DestinationMarkerIcon from '@/assets/icons/map/route-locations/destination.svg?react';
import OriginMarkerIcon from '@/assets/icons/map/route-locations/origin.svg?react';
import { RouteLocationType } from '@/types/RouteLocationType';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
  `,
);

type RouteLocationMarkerImageProps = {
  markerType: RouteLocationType;
};

export function RouteLocationMarkerIcon({ markerType }: RouteLocationMarkerImageProps) {
  const Icon = { [RouteLocationType.ORIGIN]: OriginMarkerIcon, [RouteLocationType.DESTINATION]: DestinationMarkerIcon }[
    markerType
  ];

  return (
    <StyledBox>
      <Icon />
    </StyledBox>
  );
}
