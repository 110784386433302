import { Box, css, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { ModeGuard } from '@/modules/ModeGuard';
import { TriasHelper } from '@/modules/TriasHelper';
import { Duration, IndividualTripParamStructure } from '@/modules/api-routing';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { Mode } from '@/types/Mode';

const StyledBox = styled(Box)(
  () => css`
    cursor: pointer;
  `,
);

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    font-weight: bold;
    color: ${theme.palette.common.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
);

const manipulateDuration = (duration: Duration, mode: Mode): Duration => {
  if (mode === Mode.WALK) {
    const minutes = dayjs.duration(duration).asMinutes() * 3;

    return dayjs.duration({ minutes }).toISOString() as Duration;
  }

  return duration;
};

type ResultSummaryProps = {
  mode: Mode;
  duration: Duration | undefined;
  departure?: string;
  arrival?: string;
  interchange?: number;
  changeModes?: (string | undefined)[] | undefined;
  distance?: number;
  structureIndex: number;
  hasStrategies?: boolean;
  algorithm?: IndividualTripParamStructure.algorithmType.FASTEST | IndividualTripParamStructure.algorithmType.OPTIMAL;
};

export function ResultStructureSummary({
  mode,
  duration,
  departure,
  arrival,
  interchange,
  changeModes,
  distance,
  structureIndex,
  hasStrategies,
  algorithm,
}: ResultSummaryProps) {
  const { updateSelectedMode } = useRoutesStore((state) => state.actions);

  return (
    <StyledBox
      onClick={() => {
        updateSelectedMode({ structureIndex });
      }}
    >
      {ModeGuard.isIndividualMode(mode) && (
        <>
          <Box>
            <StyledTypography>
              {(algorithm &&
                {
                  [IndividualTripParamStructure.algorithmType.FASTEST]: 'Schnellste ',
                  [IndividualTripParamStructure.algorithmType.OPTIMAL]:
                    mode === Mode.SELF_DRIVE_CAR && hasStrategies ? 'Strategiekonforme ' : 'Optimalste ',
                }[algorithm]) ||
                ''}
              Route
            </StyledTypography>
          </Box>
          <Box>
            <StyledTypography>
              {duration && TriasHelper.formatDuration(manipulateDuration(duration, mode))} |{' '}
              {TriasHelper.formatDistance(distance)}
            </StyledTypography>
          </Box>
        </>
      )}

      {ModeGuard.isIntermodalMode(mode) && (
        <>
          <StyledTypography>Via {changeModes?.join(', ')}</StyledTypography>
          <StyledTypography>
            {TriasHelper.formatTimestamp(departure)} – {TriasHelper.formatTimestamp(arrival)}
          </StyledTypography>
          <StyledTypography>
            {duration && TriasHelper.formatDuration(manipulateDuration(duration, mode))} | {interchange}{' '}
            {interchange === 1 ? 'Umstieg' : 'Umstiege'}
          </StyledTypography>
        </>
      )}
    </StyledBox>
  );
}
