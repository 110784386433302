import { Fragment } from 'react';

import StrategyIcon from '@/assets/icons/map/strategy.svg?react';
import { DetailWrapper } from '@/components/result/DetailWrapper';
import { RoadSituationElementStructure } from '@/modules/api-routing';

type StrategyDetailsProps = { strategies?: RoadSituationElementStructure[] };

export const StrategyDetails = ({ strategies }: StrategyDetailsProps) =>
  strategies?.map((roadSituation) => (
    <Fragment key={roadSituation.situationNumber?.value}>
      <DetailWrapper
        bold
        left={<StrategyIcon style={{ display: 'block' }} />}
        right={roadSituation?.description?.value}
      />
      {roadSituation?.detail?.value && <DetailWrapper right={roadSituation?.detail?.value} />}
    </Fragment>
  ));
