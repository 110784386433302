import { useQuery } from '@tanstack/react-query';
import { DataDrivenPropertyValueSpecification } from 'maplibre-gl';
import { Layer, Source } from 'react-map-gl/maplibre';

import AbnormalTrafficIcon from '@/assets/icons/map/traffic-report/abnormal-traffic.svg?url';
import AccidentIcon from '@/assets/icons/map/traffic-report/accident.svg?url';
import ConditionsIcon from '@/assets/icons/map/traffic-report/conditions.svg?url';
import ConstructionWorksIcon from '@/assets/icons/map/traffic-report/construction-works.svg?url';
import EnvironmentalObstructionIcon from '@/assets/icons/map/traffic-report/environmental-obstruction.svg?url';
import PublicEventIcon from '@/assets/icons/map/traffic-report/public-event.svg?url';
import ReroutingManagementIcon from '@/assets/icons/map/traffic-report/rerouting-management.svg?url';
import RoadOrCarriagewayOrLaneManagementIcon from '@/assets/icons/map/traffic-report/road-or-carriageway-or-lane-management.svg?url';
import WarningIcon from '@/assets/icons/map/traffic-report/warning.svg?url';
import { useMapImages } from '@/hooks/useMapImages';
import { FeatureCollectionToCenterOfMass } from '@/modules/FeatureCollectionToCenterOfMass';
import { GeoServer } from '@/modules/GeoServer';
import { ICON_SIZE } from '@/modules/IconSize';
import { IS_SATURN_INSTANCE_HE, SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { MARTIN_URL } from '@/setup/martin';
import { MapLayer } from '@/types/MapLayer';
import { TrafficReportType } from '@/types/TrafficReportType';

const ICON_DIMENSIONS = { width: 36, height: 36 };
export const TRAFFIC_REPORT_FALLBACK_ICON = 'TRAFFIC_REPORT_FALLBACK_ICON';

export function TrafficReportLayer() {
  useMapImages({
    images: [
      { name: TrafficReportType.ABNORMAL_TRAFFIC, url: AbnormalTrafficIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.ACCIDENT, url: AccidentIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.CONDITIONS, url: ConditionsIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.CONSTRUCTION_WORKS, url: ConstructionWorksIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.ENVIRONMENTAL_OBSTRUCTION, url: EnvironmentalObstructionIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.MAINTENANCE_WORKS, url: ConstructionWorksIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.PUBLIC_EVENT, url: PublicEventIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.REROUTING_MANAGEMENT, url: ReroutingManagementIcon, ...ICON_DIMENSIONS },
      {
        name: TrafficReportType.ROAD_OR_CARRIAGEWAY_OR_LANE_MANAGEMENT,
        url: RoadOrCarriagewayOrLaneManagementIcon,
        ...ICON_DIMENSIONS,
      },
      { name: TRAFFIC_REPORT_FALLBACK_ICON, url: WarningIcon, ...ICON_DIMENSIONS },
    ],
  });

  const { data: trafficReport } = useQuery({
    queryKey: [MapLayer.TRAFFIC_REPORT],
    queryFn: () => FeatureCollectionToCenterOfMass.fetch(GeoServer.wfs({ typeName: 'traffic_obstruction_geoms' })),
    enabled: IS_SATURN_INSTANCE_HE,
  });

  return (
    <Source
      key={MapLayer.TRAFFIC_REPORT}
      id={MapLayer.TRAFFIC_REPORT}
      {...(IS_SATURN_INSTANCE_HE
        ? {
            type: 'geojson',
            data: trafficReport || { type: 'FeatureCollection', features: [] },
          }
        : {
            type: 'vector',
            url: `${MARTIN_URL}/obstruction_${SATURN_INSTANCE.toLowerCase()}`,
          })}
      promoteId="id"
    >
      <Layer
        key={MapLayer.TRAFFIC_REPORT}
        id={MapLayer.TRAFFIC_REPORT}
        {...(!IS_SATURN_INSTANCE_HE && {
          'source-layer': `obstruction_${SATURN_INSTANCE.toLowerCase()}`,
        })}
        type="symbol"
        layout={{
          'icon-size': ICON_SIZE,
          'icon-allow-overlap': true,
          'icon-image': [
            'match',
            ['get', 'type'],
            ...Object.values(TrafficReportType)
              .map((value) => [value, value])
              .flat(),
            // Fallback
            TRAFFIC_REPORT_FALLBACK_ICON,
          ] as unknown as DataDrivenPropertyValueSpecification<string>,
        }}
      />
    </Source>
  );
}
