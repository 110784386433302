import { Box, TextField, Grid, styled, css } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';

import { GeocodingSearchInput } from '@/components/routing/GeocodingSearchInput';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { RouteLocationType } from '@/types/RouteLocationType';
import { TimeType } from '@/types/TimeType';

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
  `,
);

const StyledGridItem = styled(Grid)(
  () => css`
    & .MuiTextField-root {
      width: 100%;
    }
  `,
);

export function RoutingForm() {
  const time = useRoutesStore((state) => state.time);
  const { updateTime } = useRoutesStore((state) => state.actions);

  return (
    <Box>
      <form>
        <GeocodingSearchInput label="Start" routeLocationType={RouteLocationType.ORIGIN} />
        <GeocodingSearchInput label="Ziel" routeLocationType={RouteLocationType.DESTINATION} />
        <StyledGrid container spacing={1}>
          <StyledGridItem item xs={4}>
            <TextField
              select
              fullWidth
              SelectProps={{
                native: true,
              }}
              variant="standard"
              value={time.type}
              onChange={(event) => {
                updateTime({ type: event.currentTarget.value as TimeType });
              }}
            >
              <option value={TimeType.DEPARTURE}>Abfahrt</option>
              <option value={TimeType.ARRIVAL}>Ankunft</option>
            </TextField>
          </StyledGridItem>
          <StyledGridItem item xs={4}>
            <TimePicker
              disableOpenPicker
              format="HH:mm"
              value={time.date}
              onChange={(value) => {
                if (value) {
                  updateTime({ date: value });
                }
              }}
              slotProps={{ textField: { variant: 'standard' } }}
            />
          </StyledGridItem>
          <StyledGridItem item xs={4}>
            <DatePicker
              format="DD.MM.YYYY"
              value={time.date}
              onChange={(value) => {
                if (value) {
                  updateTime({ date: value });
                }
              }}
              slotProps={{ textField: { variant: 'standard' } }}
            />
          </StyledGridItem>
        </StyledGrid>
      </form>
    </Box>
  );
}
