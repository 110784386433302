import { Box, styled, css } from '@mui/material';

import { LogoInstanceLink } from '@/components/LogoInstanceLink';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(2)};
      display: flex;
    }
  `,
);

export function Header() {
  return (
    <StyledBox>
      <LogoInstanceLink />
    </StyledBox>
  );
}
