import { useTheme } from '@mui/material';
// @ts-ignore
import bbox from '@turf/bbox';
import { useEffect } from 'react';
import { Layer, Source, useMap } from 'react-map-gl/maplibre';

import BikeAndRideTransitionIcon from '@/assets/icons/map/transitions/bike-and-ride-transition.svg?url';
import ParkAndRideTransitionIcon from '@/assets/icons/map/transitions/park-and-ride-transition.svg?url';
import { useMapImages } from '@/hooks/useMapImages';
import { useRoutes } from '@/hooks/useRoutes';
import { BEFORE_LABEL_ID } from '@/modules/BeforeLabelsId';
import { TriasInterchanges } from '@/modules/TriasInterchanges';
import { TriasRouteSegments } from '@/modules/TriasRouteSegments';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { InterchangeType } from '@/types/InterchangeType';
import { Mode } from '@/types/Mode';
import { RouteLineType } from '@/types/RouteLineType';
import { RouteLocationType } from '@/types/RouteLocationType';

const ROUTE = 'ROUTE';
const ROUTE_INTERCHANGES = 'ROUTE_INTERCHANGES';
const ROUTE_INTERCHANGES_CIRCLE = 'ROUTE_INTERCHANGES_CIRCLE';
const ROUTE_INTERCHANGES_ICON = 'ROUTE_INTERCHANGES_ICON';

export const ROUTE_BORDER = 'ROUTE_BORDER';

export function RouteLayers() {
  const theme = useTheme();

  const { mode, structureIndex } = useRoutesStore((state) => state.selectedMode);
  const routeLocations = useRoutesStore((state) => state.routeLocations);

  const routes = useRoutes();

  const { current: map } = useMap();

  useEffect(() => {
    const origin = routeLocations[RouteLocationType.ORIGIN];
    const destination = routeLocations[RouteLocationType.DESTINATION];

    if (origin && destination && mode) {
      const bounds = bbox({
        type: 'FeatureCollection',
        features: [origin, destination, ...TriasRouteSegments.get(routes, { mode, structureIndex }).features].filter(
          (value) => !!value,
        ),
      });

      map?.fitBounds(bounds as [number, number, number, number], {
        padding: { top: mode !== Mode.PUBLIC_TRANSPORT ? 100 : 180, bottom: 50, left: 50, right: 50 },
        animate: true,
        duration: 300,
      });
    } else {
      [origin, destination].forEach((location) => {
        if (location && !map?.getBounds().contains(location.geometry.coordinates as [number, number])) {
          map?.panTo(location.geometry.coordinates as [number, number]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeLocations, mode, structureIndex]);

  useMapImages({
    images: [
      { name: InterchangeType.BIKE_AND_RIDE, url: BikeAndRideTransitionIcon, width: 36, height: 36 },
      { name: InterchangeType.PARK_AND_RIDE, url: ParkAndRideTransitionIcon, width: 36, height: 36 },
    ],
  });

  return (
    <>
      <Source id={ROUTE} type="geojson" data={mode ? TriasRouteSegments.get(routes, { mode, structureIndex }) : null}>
        <Layer
          id={ROUTE_BORDER}
          key={ROUTE_BORDER}
          beforeId={BEFORE_LABEL_ID}
          type="line"
          paint={{
            'line-color': 'white',
            'line-width': 12,
          }}
          layout={{ 'line-cap': 'round' }}
        />

        <Layer
          id={RouteLineType.INDIVIDUAL}
          key={RouteLineType.INDIVIDUAL}
          beforeId={BEFORE_LABEL_ID}
          type="line"
          filter={['!=', ['get', 'routeLineType'], RouteLineType.PUBLIC_TRANSPORT]}
          paint={{
            'line-color': [
              'case',
              ['==', ['get', 'routeLineType'], RouteLineType.STRATEGY_PREFERRED],
              theme.palette.success.main,
              theme.palette.primary.main,
            ],
            'line-width': 6,
            'line-opacity': 0.8,
          }}
          layout={{ 'line-cap': 'round' }}
        />

        <Layer
          id={RouteLineType.PUBLIC_TRANSPORT}
          key={RouteLineType.PUBLIC_TRANSPORT}
          beforeId={BEFORE_LABEL_ID}
          type="line"
          filter={['==', ['get', 'routeLineType'], RouteLineType.PUBLIC_TRANSPORT]}
          paint={{
            'line-color': theme.palette.primary.main,
            'line-width': 6,
            'line-opacity': 0.8,
            'line-dasharray': ['literal', [0.7, 1.4]],
          }}
          layout={{ 'line-cap': 'round' }}
        />
      </Source>

      <Source
        id={ROUTE_INTERCHANGES}
        type="geojson"
        data={mode ? TriasInterchanges.get(routes, { mode, structureIndex }) : null}
      >
        <Layer
          id={ROUTE_INTERCHANGES_CIRCLE}
          key={ROUTE_INTERCHANGES}
          type="circle"
          paint={{
            'circle-radius': 18,
            'circle-color': theme.palette.primary.main,
          }}
        />

        <Layer
          id={ROUTE_INTERCHANGES_ICON}
          key={ROUTE_INTERCHANGES_ICON}
          type="symbol"
          layout={{
            'icon-image': ['get', 'interchangeType'],
          }}
        />
      </Source>
    </>
  );
}
