import dayjs from 'dayjs';

import { Duration, RouteResultStructure } from '@/modules/api-routing';
import { Mode } from '@/types/Mode';
import { Trias } from '@/types/Trias';
import { UseRoutesResult } from '@/types/UseRoutesResult';
import { isNonNullable } from '@/utils/isNonNullable';

export class TriasHelper {
  public static getResultStructures(trias: Trias | undefined) {
    return TriasHelper.getIndividualRouteStructures(trias) || TriasHelper.getIntermodalTripStructures(trias);
  }

  public static getIndividualRouteStructures(trias: Trias | undefined) {
    return trias?.serviceDelivery?.deliveryPayload?.individualRouteResponse?.routeResult;
  }

  public static getIndividualRouteStructure(trias: Trias | undefined, index: number) {
    return TriasHelper.getIndividualRouteStructures(trias)?.[index]?.route;
  }

  public static getIntermodalTripStructures(trias: Trias | undefined) {
    return trias?.serviceDelivery?.deliveryPayload?.tripResponse?.tripResult;
  }

  public static getIntermodalTripStructure(trias: Trias | undefined, index: number) {
    return TriasHelper.getIntermodalTripStructures(trias)?.[index]?.trip;
  }

  public static getIndividualRouteStructureStrategies(routeResultStructure: RouteResultStructure) {
    return (
      routeResultStructure.route?.routeLeg
        ?.map((leg) => leg.legTrack?.trackSection?.[0]?.extension?.any?.value?.segmentReferences?.segmentReference)
        .flat()
        .filter(isNonNullable) || []
    );
  }

  public static getErrors(trias: Trias | undefined) {
    const errors =
      trias?.serviceDelivery?.deliveryPayload?.tripResponse?.errorMessage ||
      trias?.serviceDelivery?.deliveryPayload?.individualRouteResponse?.errorMessage;

    return [...new Map(errors?.filter(({ code }) => code !== 'OK').map((item) => [item.text?.text, item])).values()];
  }

  public static getDuration(routes: UseRoutesResult | undefined, mode: Mode | undefined) {
    let duration =
      routes &&
      mode &&
      (TriasHelper.getIndividualRouteStructure(routes[mode]?.data, 0)?.duration ||
        TriasHelper.getIntermodalTripStructure(routes[mode]?.data, 0)?.duration);

    if (mode === Mode.WALK && duration) {
      const minutes = dayjs.duration(duration).asMinutes() * 3;
      duration = dayjs.duration({ minutes }).toISOString() as Duration;
    }

    return duration && TriasHelper.formatDuration(duration);
  }

  public static formatDuration(duration: Duration) {
    const hours = Math.floor(dayjs.duration(duration).asHours());
    const minutes = Math.round(dayjs.duration(duration).asMinutes() % 60);

    let result = '';

    if (hours) {
      result += `${hours} h`;
    }

    if (minutes) {
      result += ` ${minutes} min`;
    }

    return result.trim();
  }

  public static formatDistance(distance: number | undefined) {
    if (distance === undefined) return '';

    if (distance < 1000) return `${distance} m`;

    return `${(distance / 1000).toFixed(1).replace('.', ',')} km`;
  }

  public static formatTimestamp = (timestamp: string | undefined) => {
    if (!timestamp) return '';

    return dayjs(timestamp).format('HH:mm');
  };
}
