import { css, Grid, styled } from '@mui/material';
import { ReactNode } from 'react';

import { transientOptions } from '@/utils/transient-options';

const StyledGrid = styled(
  Grid,
  transientOptions,
)<{ $bold?: boolean }>(
  ({ theme, $bold }) => css`
    text-align: left;
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(1)};

    ${$bold &&
    css`
      font-weight: bold;
    `}
  `,
);

type DetailWrapperProps = {
  left?: ReactNode;
  right?: ReactNode;
  bold?: boolean;
};

export function DetailWrapper({ left, right, bold }: DetailWrapperProps) {
  return (
    <StyledGrid columnSpacing={1} container alignItems="center" $bold={bold}>
      <Grid item xs={3}>
        {left}
      </Grid>

      <Grid item xs={9}>
        {right}
      </Grid>
    </StyledGrid>
  );
}
