import { TextField, Autocomplete, Box } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useThrottle } from '@/hooks/useThrottle';
import { Geocoder } from '@/modules/Geocoder';
import { useMapStore } from '@/stores/useMapStore';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { useUserStore } from '@/stores/useUserStore';
import { LocationFeature } from '@/types/LocationFeature';
import { RouteLocationType } from '@/types/RouteLocationType';

type GeocodingSearchInputProps = { label: string; routeLocationType: RouteLocationType };

export function GeocodingSearchInput({ label, routeLocationType }: GeocodingSearchInputProps) {
  const value = useRoutesStore((state) => state.routeLocations[routeLocationType]);
  const { updateRouteLocations } = useRoutesStore((state) => state.actions);
  const { setAdminMode } = useUserStore((state) => state.actions);

  const [inputValue, setInputValue] = useState('');
  const throttledInputValue = useThrottle(inputValue, 300);
  const [focus, setFocus] = useState(false);
  const [highlight, setHighlight] = useState<LocationFeature | null>(null);
  const { longitude, latitude } = useMapStore((state) => state.viewState);

  const queryParams = { q: throttledInputValue, lon: longitude, lat: latitude };
  const { data: options, isLoading } = useQuery({
    queryKey: ['geocoder', queryParams],
    queryFn: () => Geocoder.geocoding(queryParams),
    enabled: focus && throttledInputValue !== '',
    placeholderData: keepPreviousData,
  });

  const optionsIncludeValue = value && options && options.find((option) => Geocoder.areSame(option, value));
  const autocompleteOptions = [...(options || [])];
  if (value && !optionsIncludeValue) {
    autocompleteOptions.unshift(value);
  }

  const handleChange = (newLocation: LocationFeature | null) => {
    setFocus(!newLocation);
    updateRouteLocations({
      routeLocationType,
      location: newLocation || undefined,
    });
  };

  return (
    <Autocomplete
      getOptionLabel={({
        properties: {
          name: { full },
        },
      }) => full}
      isOptionEqualToValue={Geocoder.areSame}
      options={autocompleteOptions}
      autoComplete
      blurOnSelect
      includeInputInList
      loading={isLoading}
      autoSelect
      filterSelectedOptions={!optionsIncludeValue}
      filterOptions={(x) => x}
      forcePopupIcon={false}
      defaultValue={null}
      value={value || null}
      onChange={(event, newLocation) => handleChange(newLocation)}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if (['ich bin gott', 'ich bin admin', 'how do you turn this on'].includes(newInputValue.trim().toLowerCase())) {
          setAdminMode(true);
          setInputValue('');

          if (event.currentTarget instanceof HTMLInputElement) {
            event.currentTarget.blur();
          }
        } else {
          setInputValue(newInputValue);
        }
      }}
      onHighlightChange={(event, option) => {
        setHighlight(option);
      }}
      onKeyDown={(event) => {
        if (options && event.key === 'Enter' && event.currentTarget.querySelector('input') === document.activeElement) {
          handleChange(options[0]);
          event.currentTarget.querySelector('input')?.blur();
        }
        if (event.key === 'Tab') {
          const location = highlight || options?.[0];

          if (location && inputValue !== location.properties.name.firstLine) {
            event.preventDefault();
            setInputValue(location.properties.name.firstLine);
          }
        }
      }}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      renderInput={(params) => (
        <TextField sx={{ marginTop: 1 }} variant="standard" placeholder={label} {...params} fullWidth />
      )}
      renderOption={(props, location) => {
        const {
          properties: {
            name: { firstLine, secondLine },
          },
        } = location;

        const key = `${location.properties.osmId}-${location.properties.osmKey}`;

        return (
          <li {...props} key={key}>
            <Box>
              {firstLine}
              {secondLine && <Box sx={{ fontSize: '0.75rem', lineHeight: 1.1 }}>{secondLine}</Box>}
            </Box>
          </li>
        );
      }}
    />
  );
}
