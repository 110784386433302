import * as Sentry from '@sentry/react';

import { router } from '@/App';

Sentry.init({
  dsn: 'https://1f88fd2c0da94a4cb4056b9497e5b84c@sentry.trafficon.eu/17',
  enabled: import.meta.env.MODE !== 'development',
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  release: import.meta.env.VITE_SENTRY_RELEASE,
  integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],
  tracesSampleRate: import.meta.env.MODE === 'development' ? 1.0 : 0.1,
});
