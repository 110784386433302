/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router';

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as IndexImport } from './routes/index';

// Create Virtual Routes

const UeberDasProjektLazyImport = createFileRoute('/ueber-das-projekt')();
const ImpressumLazyImport = createFileRoute('/impressum')();
const DatenschutzLazyImport = createFileRoute('/datenschutz')();

// Create/Update Routes

const UeberDasProjektLazyRoute = UeberDasProjektLazyImport.update({
  id: '/ueber-das-projekt',
  path: '/ueber-das-projekt',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/ueber-das-projekt.lazy').then((d) => d.Route));

const ImpressumLazyRoute = ImpressumLazyImport.update({
  id: '/impressum',
  path: '/impressum',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/impressum.lazy').then((d) => d.Route));

const DatenschutzLazyRoute = DatenschutzLazyImport.update({
  id: '/datenschutz',
  path: '/datenschutz',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/datenschutz.lazy').then((d) => d.Route));

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/datenschutz': {
      id: '/datenschutz';
      path: '/datenschutz';
      fullPath: '/datenschutz';
      preLoaderRoute: typeof DatenschutzLazyImport;
      parentRoute: typeof rootRoute;
    };
    '/impressum': {
      id: '/impressum';
      path: '/impressum';
      fullPath: '/impressum';
      preLoaderRoute: typeof ImpressumLazyImport;
      parentRoute: typeof rootRoute;
    };
    '/ueber-das-projekt': {
      id: '/ueber-das-projekt';
      path: '/ueber-das-projekt';
      fullPath: '/ueber-das-projekt';
      preLoaderRoute: typeof UeberDasProjektLazyImport;
      parentRoute: typeof rootRoute;
    };
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '/datenschutz': typeof DatenschutzLazyRoute;
  '/impressum': typeof ImpressumLazyRoute;
  '/ueber-das-projekt': typeof UeberDasProjektLazyRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '/datenschutz': typeof DatenschutzLazyRoute;
  '/impressum': typeof ImpressumLazyRoute;
  '/ueber-das-projekt': typeof UeberDasProjektLazyRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/datenschutz': typeof DatenschutzLazyRoute;
  '/impressum': typeof ImpressumLazyRoute;
  '/ueber-das-projekt': typeof UeberDasProjektLazyRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths: '/' | '/datenschutz' | '/impressum' | '/ueber-das-projekt';
  fileRoutesByTo: FileRoutesByTo;
  to: '/' | '/datenschutz' | '/impressum' | '/ueber-das-projekt';
  id: '__root__' | '/' | '/datenschutz' | '/impressum' | '/ueber-das-projekt';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  DatenschutzLazyRoute: typeof DatenschutzLazyRoute;
  ImpressumLazyRoute: typeof ImpressumLazyRoute;
  UeberDasProjektLazyRoute: typeof UeberDasProjektLazyRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DatenschutzLazyRoute: DatenschutzLazyRoute,
  ImpressumLazyRoute: ImpressumLazyRoute,
  UeberDasProjektLazyRoute: UeberDasProjektLazyRoute,
};

export const routeTree = rootRoute._addFileChildren(rootRouteChildren)._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/datenschutz",
        "/impressum",
        "/ueber-das-projekt"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/datenschutz": {
      "filePath": "datenschutz.lazy.tsx"
    },
    "/impressum": {
      "filePath": "impressum.lazy.tsx"
    },
    "/ueber-das-projekt": {
      "filePath": "ueber-das-projekt.lazy.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
