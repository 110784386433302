import { TrafficReportFeature } from '@/types/TrafficReportFeature';
import { TrafficReportType } from '@/types/TrafficReportType';

export class TrafficReport {
  static TYPES: Record<TrafficReportType, string> = {
    [TrafficReportType.CONSTRUCTION_WORKS]: 'Bauarbeiten',
    [TrafficReportType.PUBLIC_EVENT]: 'Veranstaltung',
    [TrafficReportType.ACCIDENT]: 'Unfall',
    [TrafficReportType.ABNORMAL_TRAFFIC]: 'Stau',
    [TrafficReportType.REROUTING_MANAGEMENT]: 'Umleitung',
    [TrafficReportType.MAINTENANCE_WORKS]: 'Wartungsarbeiten',
    [TrafficReportType.ENVIRONMENTAL_OBSTRUCTION]: 'Naturereignis',
    [TrafficReportType.CONDITIONS]: 'Umweltalarm',
    [TrafficReportType.ROAD_OR_CARRIAGEWAY_OR_LANE_MANAGEMENT]: 'Sperrung/Änderung Verkehrsführung',
  };

  public static title(feature: TrafficReportFeature) {
    const { type } = feature.properties;

    return TrafficReport.TYPES[type as unknown as TrafficReportType] || `Sonstiges (${type})`;
  }
}
