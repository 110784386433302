export enum Mode {
  WALK = 'WALK',
  CYCLE = 'CYCLE',
  SELF_DRIVE_CAR = 'SELF_DRIVE_CAR',
  PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
  BIKE_AND_RIDE = 'BIKE_AND_RIDE',
  PARK_AND_RIDE = 'PARK_AND_RIDE',
}

export const isMode = (value: any): value is Mode =>
  typeof value === 'string' && (Object.values(Mode) as string[]).includes(value);
