import { Point } from 'geojson';

import { GeocoderFeature, GeocoderAPI } from '@/modules/api-geocoder';
import { LocationFeature } from '@/types/LocationFeature';

export class Geocoder {
  public static async reverse(point: Point): Promise<LocationFeature> {
    const response = await GeocoderAPI.reverse({
      lon: point.coordinates[0],
      lat: point.coordinates[1],
    });

    const full = `${point.coordinates[1].toFixed(6)}, ${point.coordinates[0].toFixed(6)}`;

    return {
      type: 'Feature',
      geometry: point,
      properties: {
        name: response.features?.length
          ? Geocoder.getNameFromGeocoderFeature(response.features?.[0])
          : { full, firstLine: full },
      },
    };
  }

  public static async geocoding(...parameters: Parameters<typeof GeocoderAPI.geocoding>) {
    return parameters[0].q === ''
      ? []
      : ((await GeocoderAPI.geocoding(...parameters))?.features || []).map((feature) =>
          Geocoder.transformToLocationFeature(feature),
        );
  }

  public static areSame(locationFeatureA: LocationFeature, locationFeatureB: LocationFeature) {
    return (
      locationFeatureA.properties.osmId === locationFeatureB.properties.osmId ||
      (locationFeatureA.geometry.coordinates[0] === locationFeatureB.geometry.coordinates[0] &&
        locationFeatureA.geometry.coordinates[1] === locationFeatureB.geometry.coordinates[1])
    );
  }

  private static getNameFromGeocoderFeature({ properties }: GeocoderFeature): LocationFeature['properties']['name'] {
    const { osm_key: osmKey, osm_value: osmValue, name, street, housenumber, postcode, city, country } = properties;

    if (osmKey === 'place' && osmValue === 'town' && postcode && name && country) {
      return {
        full: `${postcode} ${name}`,
        firstLine: `${postcode} ${name}`,
        secondLine: country,
      };
    }

    const displayName: string[] = [];

    if (name) {
      displayName.push(name);
    }

    if (street) {
      if (housenumber) {
        displayName.push(`${street} ${housenumber}`);
      } else {
        displayName.push(street);
      }
    }

    if (postcode && city) {
      displayName.push(`${postcode} ${city}`);
    } else {
      if (postcode) {
        displayName.push(postcode);
      }

      if (city) {
        displayName.push(city);
      }
    }

    const [firstLine, ...secondLine] = displayName;

    return {
      full: displayName.join(', '),
      firstLine,
      secondLine: secondLine.join(', '),
    };
  }

  private static transformToLocationFeature(feature: GeocoderFeature): LocationFeature {
    return {
      type: 'Feature',
      geometry: feature.geometry as Point,

      properties: {
        osmKey: feature.properties.osm_key,
        osmId: feature.properties.osm_id,
        name: Geocoder.getNameFromGeocoderFeature(feature),
      },
    };
  }
}
