import { ModeGuard } from '@/modules/ModeGuard';
import { TriasRequest } from '@/modules/TriasRequest';
import { IndividualTripParamStructure, RoutingAPI } from '@/modules/api-routing';
import { Mode } from '@/types/Mode';
import { RouteLocationFeature } from '@/types/RouteLocationFeature';
import { RoutesState } from '@/types/RoutesState';
import { Trias } from '@/types/Trias';

export class Routing {
  public static async get(parameters: {
    origin: RouteLocationFeature;
    destination: RouteLocationFeature;
    time: RoutesState['time'];
    mode: Mode;
  }): Promise<Trias> {
    const { mode } = parameters;

    if (mode === Mode.SELF_DRIVE_CAR) {
      const triasRequest = TriasRequest.make({
        ...parameters,
        mode,
        algorithm: IndividualTripParamStructure.algorithmType.OPTIMAL,
      });

      const [optimal, fastest] = await Promise.all([
        RoutingAPI.getIndividualRoutes({
          triasRequest,
        }) as Trias,
        RoutingAPI.getIndividualRoutes({
          triasRequest: TriasRequest.make({
            ...parameters,
            mode,
            algorithm: IndividualTripParamStructure.algorithmType.FASTEST,
          }),
        }) as Trias,
      ]);

      const merged: Trias & { triasRequest: Trias } = {
        triasRequest,

        ...optimal,
        serviceDelivery: {
          ...optimal.serviceDelivery,
          deliveryPayload: {
            individualRouteResponse: {
              errorMessage: [
                ...(optimal.serviceDelivery?.deliveryPayload?.individualRouteResponse?.errorMessage || []),
                ...(fastest.serviceDelivery?.deliveryPayload?.individualRouteResponse?.errorMessage || []),
              ],
              routeResult: [
                ...(optimal.serviceDelivery?.deliveryPayload?.individualRouteResponse?.routeResult || []),
                ...(fastest.serviceDelivery?.deliveryPayload?.individualRouteResponse?.routeResult || []),
              ],
              individualRouteResponseContext: {
                situations: {
                  roadSituation: [
                    ...(optimal.serviceDelivery?.deliveryPayload?.individualRouteResponse
                      ?.individualRouteResponseContext?.situations?.roadSituation || []),
                    ...(fastest.serviceDelivery?.deliveryPayload?.individualRouteResponse
                      ?.individualRouteResponseContext?.situations?.roadSituation || []),
                  ],
                },
              },
            },
          },
        },
      };

      return merged;
    }

    if (ModeGuard.isIndividualMode(mode)) {
      const triasRequest = TriasRequest.make({ ...parameters, mode });

      return {
        ...((await RoutingAPI.getIndividualRoutes({
          triasRequest,
        })) as Trias),
        triasRequest,
      };
    }

    if (ModeGuard.isIntermodalMode(mode)) {
      const triasRequest = TriasRequest.make({ ...parameters, mode });

      return {
        ...((await RoutingAPI.getIntermodalTrips({
          triasRequest,
        })) as Trias),
        triasRequest,
      };
    }

    throw new Error('Unknown routing mode request');
  }
}
