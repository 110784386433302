import { MultiPolygon } from 'geojson';
import { useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl/maplibre';

import borderShapeBw from '@/assets/border-shapes/bw.json?url';
import borderShapeBy from '@/assets/border-shapes/by.json?url';
import borderShapeDe from '@/assets/border-shapes/de.json?url';
import borderShapeHe from '@/assets/border-shapes/he.json?url';
import { BEFORE_LABEL_ID } from '@/modules/BeforeLabelsId';
import { SATURN_INSTANCE } from '@/modules/SaturnInstance';
import { SaturnInstance } from '@/types/SaturnInstance';

const geometryUrl = {
  [SaturnInstance.BW]: borderShapeBw,
  [SaturnInstance.BY]: borderShapeBy,
  [SaturnInstance.HE]: borderShapeHe,
  [SaturnInstance.DE]: borderShapeDe,
}[SATURN_INSTANCE];

const BORDER_SHAPE = 'BORDER_SHAPE';

export function BorderShape() {
  const [shape, setShape] = useState<MultiPolygon>();

  useEffect(() => {
    (async () => {
      setShape(await (await fetch(geometryUrl)).json());
    })();
  }, []);

  return shape ? (
    <Source
      key={BORDER_SHAPE}
      type="geojson"
      data={{
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [0, 90],
              [180, 90],
              [180, -90],
              [0, -90],
              [-180, -90],
              [-180, 0],
              [-180, 90],
              [0, 90],
            ],
            ...shape.coordinates[0],
          ],
        ],
      }}
    >
      <Layer
        key={BORDER_SHAPE}
        type="fill"
        beforeId={BEFORE_LABEL_ID}
        paint={{ 'fill-color': 'black', 'fill-opacity': 0.1 }}
      />
    </Source>
  ) : null;
}
