import { Box, css, styled } from '@mui/material';
import { Link } from '@tanstack/react-router';

import { Logo } from '@/components/Logo';
import { SATURN_INSTANCE_LABEL } from '@/modules/SaturnInstanceLabel';

const StyledLink = styled(Link)(
  ({ theme }) => css`
    flex: 1 0 auto;
    color: ${theme.palette.common.white};
    display: flex;
    text-decoration: none;
    align-items: center;
    gap: ${theme.spacing(2)};
    font-weight: bold;
    justify-content: space-between;

    ${theme.breakpoints.up('md')} {
      align-items: flex-end;
      line-height: 1;
    }
  `,
) as unknown as typeof Link;

export function LogoInstanceLink() {
  return (
    <StyledLink to="/">
      <Logo />

      {SATURN_INSTANCE_LABEL && <Box>{SATURN_INSTANCE_LABEL}</Box>}
    </StyledLink>
  );
}
